import {action, observable} from "mobx";
import React from "react";

const SimpleList = (list) => (<ul>{list.map((item, index) => <li key={index}>{item}</li>)}</ul>);

class SnackbarStore {

	@observable
	snacks = [];

	@action
	addSnack(title, message, failure = false, dismissAfter = 3000) {
		let key = + new Date();
		let _message = Array.isArray(message) && SimpleList(message) || JSON.stringify(message, null, 2);

		let actionColor = "#007bff";
		if(failure || title.includes("ERROR")) {
			actionColor = "orangered";
		}

		this.snacks.push({
			message: _message,
			key: key,
			action: 'Lukk',
			title: title,
			dismissAfter: dismissAfter,
			onClick: () => this.deleteByKey(key),
			actionStyle: {color: actionColor}
		});
	}

	@action
	deleteByKey(key) {
		this.snacks.replace(this.snacks.filter(snack => snack.key !== key));
	}

	@action
	delete(snack) {
		this.snacks.remove(snack);
	}

}

const snackbarStore = new SnackbarStore();

export default snackbarStore;

