import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, NavItem, NavLink, Nav, TabPane, TabContent, InputGroup, InputGroupAddon, Input} from 'reactstrap';
import PropTypes from "prop-types";
import autobind from "autobind-decorator";
import classnames from 'classnames';
import {inject, observer} from "mobx-react";
import {ENTITY_TYPE} from "./Decorators";
import FocusLock from 'react-focus-lock';
import {XP_API_KEY, RECIPE_API} from "../../utils/consts";
import SearchInput from "../SearchInput";
import axios from "axios";
import {action} from "mobx";
import snackbarStore from "../../stores/SnackbarStore";
import enonicStore from "../../stores/EnonicStore";
import {enonicSearchApiConfig, recipeSearchApiConfig} from "../../utils/enonicConfig";

@inject("enonicStore")
@inject("snackbarStore")
@observer
class LinkModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			displayName: "",
			text: "",
			url: "",
			activeTab: ENTITY_TYPE.HTML_LINK
		};
	}

	componentDidUpdate(prevProps) {
		if(prevProps.selectedTextAndEntity !== this.props.selectedTextAndEntity) {
			const {text, url, type, start, end} = this.props.selectedTextAndEntity;
			this.setState({
				text: text,
				url: url,
				activeTab: type,
				start: start,
				end: end,
				displayName: ""
			});
			this.fetchDisplayName(url, type);
		}
	}

	@autobind
	fetchDisplayName(url, type) {

		if(type === ENTITY_TYPE.ENONIC_LINK) {
			const opts = {
				params: {
					apikey: `${XP_API_KEY}`,
					rootPath: this.props.enonicStore.site.rootpath,
					q: url.replace("enonic://", "")
				}
			};

			// TODO: Api for fetching displayName of Enonic content missing
			// axios.get(`${XP_API}/_/service/no.tine.recipepublish/recipe`, opts)
			// 	.then((response) => this.setState({displayName: response.data.displayName}))
			// 	.catch(this.handleError);
			this.setState({displayName: url});


		} else if(type === ENTITY_TYPE.RECIPE_LINK) {
			axios.get(`${RECIPE_API}/recipes/` + url.replace("recipedb://", "") )
				.then((response) => this.setState({displayName: response.data.displayName}))
				.catch(this.handleError);
		}

	}

	@action.bound
	handleError(error) {
		console.error(error);
		snackbarStore.addSnack("ERROR", "Noe gikk galt ved søk... sjekk konsollen for mer informasjon");
	}

	@autobind
	handleTabSelect(tabId, e) {
		if((e.type === "keydown" &&  (e.keyCode === 13 || e.keyCode === 32)) // space or enter key
			|| e.type === "click") { // or mouse click
			e.preventDefault();
			if (this.state.activeTab !== tabId) {
				this.setState({activeTab: tabId, url: "", displayName: ""});
			}
		}
	}

	@autobind
	handleRecipeSelected(item) {
		if(!this.state.text) {
			this.setState({text: item.displayName});
		}
		this.setState({url: "recipedb://" + item.recipeId});
	}

	@autobind
	handleEnonicSelected(item) {
		if(!this.state.text) {
			this.setState({text: item._source.displayName});
		}
		this.setState({url: "enonic://" + item._source.id});
	}

	@autobind
	setUrl(e) {
		this.setState({url: e.target.value});
	}

	@autobind
	setText(e) {
		this.setState({text: e.target.value});
	}

	@autobind
	onSubmit() {
		this.props.renderSuggestion({
			text: this.state.text,
			url: this.state.url,
			type: this.state.activeTab,
			start: this.state.start,
			end: this.state.end
		});
		this.props.onModalToggle();
	}


	render() {
		const {isOpen, onModalToggle} = this.props;

		const {activeTab} = this.state;

		return (
			<Modal isOpen={isOpen} toggle={onModalToggle} autoFocus={false} className={this.props.className} fade={false}>
				<ModalHeader toggle={onModalToggle}>Legg til ekstern lenke</ModalHeader>
				<FocusLock disabled={!isOpen}>
					<ModalBody>

						<Nav tabs>
							<NavItem>
								<NavLink tabIndex="1"
								         className={classnames({ active: activeTab === ENTITY_TYPE.HTML_LINK })}
								         onKeyDown={(e) => this.handleTabSelect(ENTITY_TYPE.HTML_LINK, e)}
								         onClick={(e) => this.handleTabSelect(ENTITY_TYPE.HTML_LINK, e)}
								>
									Link
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tabIndex="2"
								         className={classnames({ active: activeTab === ENTITY_TYPE.RECIPE_LINK })}
								         onKeyDown={(e) => this.handleTabSelect(ENTITY_TYPE.RECIPE_LINK, e)}
								         onClick={(e) => this.handleTabSelect(ENTITY_TYPE.RECIPE_LINK, e)}
								>
									Oppskrift
								</NavLink>
							</NavItem>
							<NavItem >
								<NavLink tabIndex="3"
								         className={classnames({ active: activeTab === ENTITY_TYPE.ENONIC_LINK })}
								         onKeyDown={(e) => this.handleTabSelect(ENTITY_TYPE.ENONIC_LINK, e)}
								         onClick={(e) => this.handleTabSelect(ENTITY_TYPE.ENONIC_LINK, e)}
								>
									Enonic XP
								</NavLink>
							</NavItem>
						</Nav>


						<TabContent activeTab={activeTab}>

							<TabPane tabId={ENTITY_TYPE.HTML_LINK}>
								<InputGroup className="mt-3">
									<InputGroupAddon addonType="prepend">URL</InputGroupAddon>
									<Input tabIndex="4" id="link-url" autoFocus={true} value={this.state.url} placeholder="eksempelvis https://vg.no/" onChange={this.setUrl} />
								</InputGroup>
								<InputGroup className="mt-3">
									<InputGroupAddon addonType="prepend">Tekst</InputGroupAddon>
									<Input tabIndex="5" id="link-text" value={this.state.text} onChange={this.setText} />
								</InputGroup>
							</TabPane>

							<TabPane tabId={ENTITY_TYPE.RECIPE_LINK}>
								<InputGroup className="mt-3">
									<InputGroupAddon addonType="prepend">Oppskrift</InputGroupAddon>
									<SearchInput field="displayName"
									             id="linkRecipeSearch"
									             onSelectItem={this.handleRecipeSelected}
									             apiConfig={recipeSearchApiConfig}
									             placeholder="Søk og velg oppskrift"
									             value={this.state.displayName}
									             className="form-control"
									             tabIndex={6}
									/>
								</InputGroup>
								<InputGroup className="mt-3">
									<InputGroupAddon addonType="prepend">Tekst</InputGroupAddon>
									<Input tabIndex="8" id="recipe-text" value={this.state.text} onChange={this.setText} />
								</InputGroup>
							</TabPane>

							<TabPane tabId={ENTITY_TYPE.ENONIC_LINK}>
								<InputGroup className="mt-3">
									<InputGroupAddon addonType="prepend">Enonic</InputGroupAddon>
									<SearchInput field="_source.displayName"
									             id="linkEnonicSearch"
									             onSelectItem={this.handleEnonicSelected}
									             apiConfig={enonicSearchApiConfig}
									             placeholder="Søk og velg XP innehold"
									             value={this.state.displayName}
									             className="form-control"
									             tabIndex={9}
									/>
								</InputGroup>
								<InputGroup className="mt-3">
									<InputGroupAddon addonType="prepend">Tekst</InputGroupAddon>
									<Input tabIndex="10" id="recipe-text" value={this.state.text} onChange={this.setText} />
								</InputGroup>
							</TabPane>

						</TabContent>

					</ModalBody>
					<ModalFooter>
						<Button tabIndex="11" color="primary" onClick={this.onSubmit}>Velg</Button>
						<Button tabIndex="12" color="secondary" onClick={onModalToggle}>Lukk</Button>
					</ModalFooter>
				</FocusLock>
			</Modal>
		);
	}
}

LinkModal.propTypes = {
	isOpen : PropTypes.bool,
	onModalToggle: PropTypes.func,
	renderSuggestion: PropTypes.func,
	selectedTextAndEntity: PropTypes.object
};

export default LinkModal;