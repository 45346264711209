import React from 'react';
import autobind from 'autobind-decorator';
import { observer, inject } from 'mobx-react';
import PropTypes from "prop-types";
import ImageSelector from "./ImageSelector";
import YouTubeVideoSelector from "./YouTubeVideoSelector";
import idGenerator from "react-id-generator";

@inject("snackbarStore")
@observer
class MediaSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tooltipOpen: false
		};
		this.htmlId = idGenerator();
	}

	@autobind
	toggleTooltip() {
		this.setState({tooltipOpen: !this.state.tooltipOpen});
	}

	render() {
		const { media, onDeleteCallback, showIcon = false, bigIcon, mandatory } = this.props;

		return (
			<React.Fragment>
				<div id={this.htmlId} className="media-container">
					{ media.mediaType === "IMAGE" &&
						<ImageSelector image={media} showIcon={showIcon} bigIcon={bigIcon} onDeleteCallback={onDeleteCallback} mandatory={mandatory} />
					||
						<YouTubeVideoSelector video={media} showIcon={showIcon} bigIcon={bigIcon} onDeleteCallback={onDeleteCallback} />
					}
				</div>
			</React.Fragment>
		);
	}
}

MediaSelector.propTypes = {
	media: PropTypes.object,
	onDeleteCallback: PropTypes.func,
	showIcon: PropTypes.bool,
	bigIcon: PropTypes.bool,
	mandatory: PropTypes.bool,
	id: PropTypes.string
};

export default MediaSelector;