import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from "@js-seo/draft-js-export-html"
import {ContentState, CompositeDecorator, EditorState, SelectionState, Modifier, Entity} from "draft-js";
import {EnonicDecorator, LinkDecorator, RecipeDecorator} from "./Decorators";
import { ENTITY_TYPE } from "./Decorators";

const compositeDecorator = new CompositeDecorator([RecipeDecorator, EnonicDecorator, LinkDecorator]);

function convertToHTML(contentState) {
	let options = {
		entityStyleFn: entityStyler,
		defaultBlockTag: null
	};
	return stateToHTML(contentState, options);
}

/**
 * Styles html elements such as a, span, etc. for rendering
 * depending on entity type
 *
 * @param entity The Entity object to style
 * @returns {*} Styled html element with attributes
 */
function entityStyler(entity) {
	let entityStyle;
	let entityType = entity.getType();

	if (entityType === ENTITY_TYPE.RECIPE_LINK || entityType === ENTITY_TYPE.ENONIC_LINK) {
		const data = entity.getData();
		entityStyle = {
			element: 'a',
			attributes: {
				'data-unresolved-url': data.url,
			}
		};
	} else if (entityType === ENTITY_TYPE.HTML_LINK) {
		const data = entity.getData();
		entityStyle = {
			element: 'a',
			attributes: {
				href: data.url,
			}
		};
	}

	return entityStyle;
}

/**
 * Parse inline elements such as a, span, etc. when
 * when restoring contentState from text
 *
 * @param element The element to parse
 * @param {Style, Entity} Return types
 * @returns Style or Entity
 */
function entityParser(element, {Style, Entity}) {
	const unresolvedLink = element.getAttribute('data-unresolved-url');
	let entity;
	if (element.tagName === "A" && unresolvedLink) {
		if(unresolvedLink.startsWith("recipedb://")) {
			entity = new Entity(ENTITY_TYPE.RECIPE_LINK, {url: unresolvedLink}, 'MUTABLE');
		} else {
			entity = new Entity(ENTITY_TYPE.ENONIC_LINK, {url: unresolvedLink}, 'MUTABLE');
		}
	} else {
		entity = new Entity(ENTITY_TYPE.HTML_LINK, {url: element.getAttribute('href')}, 'MUTABLE');
	}

	return entity;
}

function convertFromHTML(htmlValue) {
	let options = {
		customInlineFn: entityParser
	};
	return stateFromHTML(htmlValue, options);
}

function InitEditorState(htmlValue) {
	let editorState;
	if (htmlValue) {
		const contentState = convertFromHTML(htmlValue);
		editorState = EditorState.createWithContent(contentState, compositeDecorator);
	} else {
		editorState = EditorState.createEmpty(compositeDecorator);
	}
	return editorState;
}

const InsertExternalContentLink = (editorState, result) => {

	const contentStateWithEntity = editorState.getCurrentContent().createEntity(result.type, 'MUTABLE', {url: result.url});

	const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

	let updatedSelectionState = editorState.getSelection().merge({anchorOffset: result.start, focusOffset: result.end});

	let newContentState = Modifier.replaceText(contentStateWithEntity, updatedSelectionState, `${result.text}`, null, entityKey);

	const newEditorState = EditorState.push(editorState, newContentState, `insert-link`);

	return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};

function getRangeOfEntity(entityKey, contentBlock) {

	let start = undefined, end = undefined;
	let nextEntity = null;
	const characterList = contentBlock.getCharacterList();

	for (let i = 0; i < characterList.size; i++) {
		let entity = characterList.get(i).getEntity();
		if((i+1) < characterList.size) {
			nextEntity = characterList.get(i+1).getEntity();
		} else {
			nextEntity = null;
		}

		if(typeof start === "undefined" && entity === entityKey) {
			start = i;
		}

		if(typeof start !== "undefined" && nextEntity !== entityKey) {
			end = i + 1;
			break;
		}
	}

	return {start: start, end: end};
}

function getSelectedTextAndEntity(editorState) {

	let selectionState = editorState.getSelection();
	let currentContent = editorState.getCurrentContent();
	let currentContentBlock = currentContent.getBlockForKey(selectionState.getAnchorKey());

	let start = selectionState.getStartOffset();
	let end = selectionState.getEndOffset();

	const blockText = currentContentBlock.getText();

	let url = null;
	let type = ENTITY_TYPE.HTML_LINK;

	let entityKey = currentContentBlock.getEntityAt(start);

	if(entityKey) { // select word(s) from entity instead if present
		({start, end} = getRangeOfEntity(entityKey, currentContentBlock));
		const entity = currentContent.getEntity(entityKey);
		url = entity.data.url;
		type = entity.type;
	}

	if(start === end) { // no text is selected - select the word that the cursor is in
		let startOfWordOffset = blockText.lastIndexOf(" ", start);
		if(startOfWordOffset === -1) {
			start = 0;
		} else {
			start = startOfWordOffset === 0 ? startOfWordOffset : startOfWordOffset + 1;
		}

		let endOfWordOffset = blockText.indexOf(" ", end);
		if(endOfWordOffset === -1) {
			end = blockText.length;
		} else {
			end = endOfWordOffset;
		}
	}

	let selectedText = blockText.slice(start, end);
	return {text: selectedText || "", url: url || "", type: type, start: start, end: end};
}

function setSelectionRange(editorState, start, end) {
	let selectionState = editorState.getSelection().merge({
		anchorOffset: start,
		focusOffset: end,
	});
	return EditorState.forceSelection(editorState, selectionState);
}


/**
 * Returns a new EditorState where the Selection is at the end.
 *
 * This ensures to mimic the textarea behaviour where the Selection is placed at
 * the end. This is needed when blocks (like stickers or other media) are added
 * without the editor having had focus yet. It still works to place the
 * Selection at a specific location by clicking on the text.
 */
const moveSelectionToEnd = editorState => {
	const content = editorState.getCurrentContent();
	const blockMap = content.getBlockMap();

	const key = blockMap.last().getKey();
	const length = blockMap.last().getLength();

	// On Chrome and Safari, calling focus on contenteditable focuses the
	// cursor at the first character. This is something you don't expect when
	// you're clicking on an input element but not directly on a character.
	// Put the cursor back where it was before the blur.
	const selection = new SelectionState({
		anchorKey: key,
		anchorOffset: length,
		focusKey: key,
		focusOffset: length,
	});
	return EditorState.acceptSelection(editorState, selection);
};



export { InitEditorState, InsertExternalContentLink, convertToHTML, getSelectedTextAndEntity, setSelectionRange, moveSelectionToEnd };