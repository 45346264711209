import { observable, action } from 'mobx';
import { RECIPE_API, STORAGE_KEY } from "../utils/consts";
import axios from 'axios';

class AuthenticationStore {
	@observable loggedIn = false;
	@observable authInited = false;

	@action
	login(username, password) {
		return axios.get(`${ RECIPE_API}/apps/verifyuser`, { params: { username, password }})
			.then(this.handleLoginSuccess);
	}

	@action.bound
	handleLoginSuccess(result) {
		this.setToken(result.data.token);
	}

	@action.bound
	removeToken() {
		localStorage.removeItem(STORAGE_KEY);
		delete axios.defaults.headers.common['Authorization'];
		this.loggedIn = false;
	}

	@action.bound
	setToken(token) {
		localStorage.setItem(STORAGE_KEY, token);
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		this.loggedIn = true;
	}

	@action.bound
	verifyLoggedIn(token) {
		const config =
		{
			headers: {
				'Authorization': 'Bearer ' + token
			}
		};

		return axios.get(`${RECIPE_API}/apps/verifytoken`, config)
			.then(() => this.setToken(token))
			.catch(() => this.removeToken())
			.then(() => (this.authInited = true));
	}
}

const authStore = new AuthenticationStore();

export default authStore;