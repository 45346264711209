// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("icons/icomoon.ttf?og2prx", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable */
@font-face {
    font-family: 'icomoon';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'], [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    box-sizing: inherit;
    font-size: 40px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
    content: "\\e92d";
}`, "",{"version":3,"sources":["webpack://./assets/font-icons.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,sBAAsB;IACtB,+DAAuD;IACvD,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,+EAA+E;IAC/E,iCAAiC;IACjC,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,oBAAoB;IACpB,cAAc;IACd,mBAAmB;IACnB,eAAe;;IAEf,sCAAsC;IACtC,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["/* stylelint-disable */\r\n@font-face {\r\n    font-family: 'icomoon';\r\n    src: url('icons/icomoon.ttf?og2prx') format('truetype');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n}\r\n\r\n[class^='icon-'], [class*=' icon-'] {\r\n    /* use !important to prevent issues with browser extensions that change fonts */\r\n    font-family: 'icomoon' !important;\r\n    speak: none;\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    font-variant: normal;\r\n    text-transform: none;\r\n    line-height: 1;\r\n    box-sizing: inherit;\r\n    font-size: 40px;\r\n\r\n    /* Better Font Rendering =========== */\r\n    -webkit-font-smoothing: antialiased;\r\n    -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n.icon-play:before {\r\n    content: \"\\e92d\";\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
