import React from 'react';
import { Card, CardImg, CardBody, CardTitle, CardText, CardImgOverlay } from 'reactstrap';
import { Link } from 'react-router-dom';
import { constructImageUrl } from '../../utils/mediaUtils';
import '../../../assets/media.css';
import '../../../assets/font-icons.css';

const contributorLogos = {
	"TINE Kjøkken": require("../../images/tine-kjokken-logo.png").default,
	"Gryr": require("../../images/gryr-logo.png").default,
	"Smart Idrettsmat": require("../../images/tine-smartidrettsmat-logo.png").default
};

const formatDate = (dateAsString) => {
	if (dateAsString) {
		const date = new Date(dateAsString);
		return doubleDigits(date.getDate()) + "." + doubleDigits(date.getMonth() + 1) + "." + date.getFullYear();
   } else {
      return "";
	}
};

const doubleDigits = (number) => {
   return number < 10 ? "0" + number : number;
};

class ResultCard extends React.Component {
	render() {
		const { recipeId, displayName, description, imageUrl, contributor, videoUrl, created } = this.props;

		return (
			<Card className={"w-stacked-card mb-4 ml-4 float-left"}>
				<Link to={`/recipe/${recipeId}`}>
					<CardImg top width="100%" src={imageUrl && constructImageUrl(imageUrl, 368)} alt={displayName} />
					<CardImgOverlay>
						<div className="contrib-background" />
						<img className="contrib-logo" alt={contributor} src={contributorLogos[contributor]} />
						{videoUrl ? <div className="play-icon"><i className="icon-play"/></div> : "" }
					</CardImgOverlay>
				</Link>
				<CardBody>
					<Link to={`/recipe/${recipeId}/overview`}>
						<CardTitle className="max-two-lines">{displayName}</CardTitle>
					</Link>
					<CardText className="max-three-lines">{description}</CardText>
					<div className="search-res-date">{formatDate(created)}</div>
				</CardBody>
			</Card>
		);
	}
}

export default ResultCard;
