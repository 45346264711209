import React from 'react';
import { Tooltip } from 'reactstrap';

class AnchorTag extends React.Component {

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			tooltipOpen: false
		};
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}

	render() {
		const {children, contentState, entityKey} = this.props;
		const id = "entity-" + entityKey;

		let entity = contentState.getEntity(entityKey);
		let type = entity.getType();
		let attributes;

		if (type === ENTITY_TYPE.ENONIC_LINK || type === ENTITY_TYPE.RECIPE_LINK) {
			attributes = {
				'data-unresolved-url': entity.data.url,
				href: ""
			};
		} else {
			attributes = {
				href: entity.data.url
			};
		}
		return (
			<span>
				<a id={id} className='external-link' {...attributes} >{children}</a>
				<Tooltip
					placement="top"
					modifiers={{
						preventOverflow: { boundariesElement: 'viewport' },
						offset: { offset: 2 },
					}}
					offset="50"
					isOpen={this.state.tooltipOpen}
					autohide={false}
					target={id}
					toggle={this.toggle}>
					<a className='external-link' target="_blank" href={entity.data.url}>{entity.data.url}</a>
				</Tooltip>
			</span>
		);
	}
}

function findRecipeEntities(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges(
		(characterMetadata) => filterEntities(characterMetadata, ENTITY_TYPE.RECIPE_LINK, contentState),
		callback
	);
}


function findEnonicEntities(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges(
		(characterMetadata) => filterEntities(characterMetadata, ENTITY_TYPE.ENONIC_LINK, contentState),
		callback
	);
}


function findLinkEntities(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges(
		(characterMetadata) => filterEntities(characterMetadata, ENTITY_TYPE.HTML_LINK, contentState),
		callback
	);
}

function filterEntities(characterMetadata, entityType, contentState) {
	const entityKey = characterMetadata.getEntity();
	return (entityKey !== null && contentState.getEntity(entityKey).getType() === entityType);
}

const ENTITY_TYPE =  { RECIPE_LINK: 'RECIPE_LINK', ENONIC_LINK: 'ENONIC_LINK', HTML_LINK: 'HTML_LINK' };

const RecipeDecorator = { strategy: findRecipeEntities, component: AnchorTag };
const EnonicDecorator = { strategy: findEnonicEntities, component: AnchorTag };
const LinkDecorator   = { strategy: findLinkEntities, component: AnchorTag };

export {RecipeDecorator, EnonicDecorator, LinkDecorator, ENTITY_TYPE};