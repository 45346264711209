import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("authStore")
@observer
class PrivateRoute extends React.Component {

	render() {
		const {component: Component, authStore, ...rest} = this.props;
		return <Route {...rest} render={(props) => (
			authStore.loggedIn === true
				? <Component {...props} />
				: <Redirect to={{
					pathname: '/authentication',
					state: { from: props.location }
				}} />
		)}/>;
	}
}

export default PrivateRoute;