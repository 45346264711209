import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import { MdDelete } from 'react-icons/md';

@inject("recipeStore")
@observer
class SelectedRelatedXpArticles extends React.Component {

	constructor(props) {
		super(props);
	}

	@autobind
	handleClick(contentId) {
		const { recipeStore } = this.props;
		recipeStore.removeRelatedXpArticle(contentId);
	}


	render() {
		const { recipeStore } = this.props;
		return (
			<Fragment>
				<ul>
					{this.getArticlesAsLines(recipeStore)}
				</ul>
			</Fragment>
		);
	}

    getArticlesAsLines(recipeStore) {
        return recipeStore.currentRecipe.relatedXpArticles
            .map((result) =>
                <li key={result.id} data-recipe-id={result.id}>
                    {result.displayName} <MdDelete className="clickable delete-icon" style={{verticalAlign: 'middle'}}
                                                   onClick={() => this.handleClick(result.id)}/>
                </li>
            );
    }
}

SelectedRelatedXpArticles.propTypes = {

};


export default SelectedRelatedXpArticles;