import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import SearchInput from "./SearchInput";
import classnames from 'classnames';
import { get } from 'lodash-es';


class CharCounter extends React.Component {

	render() {
		const { source, id, charCounterMax, placeholder } = this.props;

		let str = get(source, id, "");

		if(!str) {
			str = placeholder;
		}

		if(str) { // draftjs replaces trailing space with non-breaking space character
			str = str.replace(/&nbsp;$/g, " ")
		}

		const aboveMaxChar =  (charCounterMax && str) ? str.length >= charCounterMax : false;

		return (
			<Fragment>
				{str &&
					<span className="small float-right">
					<span className={classnames({"text-danger": aboveMaxChar})}>{str.length}</span>
						{charCounterMax &&
						<span> / {charCounterMax}</span>
						}
				</span>
				}
			</Fragment>
		);
	}
}

SearchInput.propTypes = {
	source: PropTypes.object,
	id: PropTypes.string,
	charCounterMax: PropTypes.number
};

export default CharCounter;

