import React from 'react';
import Autosuggest from 'react-autosuggest';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { observable, action } from 'mobx';
import axios from 'axios';
import {get} from "lodash-es";

@inject("uiStore")
@inject("snackbarStore")
@observer
class SearchInput extends React.Component {
	@observable.shallow suggestions = [];
	@observable value = "";
	@observable totalCount = 0;
	hasUpdatedMandatory = false;
	lastSelected = "";

	constructor(props) {
		super(props);
		if(props.mandatory) {
			this.updateMandatoryStatus();
		}
		this.tokenSource = axios.CancelToken.source();
	}

	componentDidMount() {
		if(this.props.value) {
			this.value = this.props.value;
		}
	}

	componentDidUpdate(prevProps) {
		const { mandatory } = this.props;

		if(prevProps.value !== this.props.value) {
			this.value = this.props.value;
		}

		if(mandatory) {
			if(prevProps.value !== this.props.value || !this.hasUpdatedMandatory) {
				this.updateMandatoryStatus();
				this.hasUpdatedMandatory = true;
			}
		}
	}

	componentWillUnmount() {
		const { uiStore, source, id, mandatory } = this.props;
		if(mandatory) {
			uiStore.removeMandatoryFieldComponent(source, id);
		}
	}

	@autobind
	updateMandatoryStatus() {  // keep track of components that are mandatory
		const { uiStore, source, id } = this.props;

		if(this.value) {
			uiStore.removeMandatoryFieldComponent(source, id);
		} else {
			uiStore.addMandatoryFieldComponent(source, id);
		}
	}

	@autobind
	handleSuggestionsFetchRequested({ value, reason }) {

		if(reason === "input-focused") { // don't show suggestions until user changes value
			return;
		}

		const { apiConfig, snackbarStore, ...rest } = this.props;
		const { searchKey, ...queryParams } = apiConfig.queryParams;

		this.tokenSource.cancel("New request supercedes previous request");
		this.tokenSource = axios.CancelToken.source();

		axios.get(apiConfig.endpoint, { cancelToken: this.tokenSource.token, params: {[searchKey]: value, ...queryParams} })
			.then(this.handleSearchResults)
			.catch(function(thrown) {
				if (axios.isCancel(thrown)) {
					console.log("Request canceled:", thrown.message);
				} else {
					console.error("Error while fetching ingredient ", thrown.message);
					snackbarStore.addSnack("ERROR", "Problemer med å søke - se konsoll for mer info.");
				}
			});
	}

	@action.bound
	handleSearchResults(results) {
		const { field } = this.props;
		const { dataNode, ...rest } = this.props.apiConfig;
		let data = dataNode ? get(results.data, dataNode, "") : results.data;
		data.sort((a, b) => get(a, field, "").length > get(b,field, "").length ? 1 : -1);

		this.totalCount = data.length;
		this.suggestions.replace(data);
	}

	@autobind
	handleSuggestionsClearRequested() {
		this.suggestions.clear();
	}

	@autobind
	handleSuggestionHighlighted(data) {
		if(data.suggestion) {
			// This sets the text of the input when a selection is highlighted,
			// but this can give the user a false sense of having selected the item.
			// Going to leave what the user entered for now. Feels like a better UX.

			//this.value = data.suggestion[this.props.field];
		}
	}

	@autobind
	handleSuggestionSelected(e, data) {
		this.value = get(data.suggestion, this.props.field);
		if(this.props.onSelectItem) {
			this.props.onSelectItem(data.suggestion, this.value);
		}
		if(this.props.clearInputOnSelect) {
			this.value = "";
		}
        this.lastSelected = this.value;
	}

	@autobind
	handleFieldChange(e) {
		this.value = e.target.value;
        this.lastSelected = "";
	}

	@autobind
	handleOnBlur(e, data) {
		// reset to value - prevents user from leaving an arbitrary string
		if (this.value === "" && this.props.handleEmptyValue) {
            this.props.handleEmptyValue()
		} else if (this.lastSelected !== "") {
			this.value = this.lastSelected;
        } else if(this.props.value) {
			this.value = this.props.value;
		} else {
			this.value = this.lastSelected;
		}

		if(data.highlightedSuggestion) {
			this.handleSuggestionSelected(null, {suggestion: data.highlightedSuggestion});
		}
	}

	render() {
		const { className, filterCallback, colorizeCallback, mandatory, renderSuggestion, onSelectItem, value, apiConfig, source, uiStore, snackbarStore, clearInputOnSelect, handleEmptyValue, ...rest } = this.props;

	    let _className = (className ? className : "") + " form-control";
		if(mandatory && !this.value) {
			_className += " is-invalid";
		}

		const inputProps = {
			value: this.value,
			onChange: this.handleFieldChange,
			onBlur: this.handleOnBlur,
			className: `${_className}`,
			...rest
		};

		const maxVisibleSuggestions = 17;
		const filterFunc = (filterCallback && typeof filterCallback === 'function') ? filterCallback : () => true;

		const visibleSuggestions = this.suggestions.filter(filterFunc).slice(0, maxVisibleSuggestions);
		if(this.totalCount > maxVisibleSuggestions && visibleSuggestions.length > 0) {
			visibleSuggestions.push({
				[this.props.field]: `Fler treff finnes, juster søket ditt for å se disse...`
			});
		}

		const colorFunc = (colorizeCallback && typeof colorizeCallback === 'function') ? colorizeCallback : () => "";
		const renderSuggestFunc = (renderSuggestion && typeof renderSuggestion === 'function') ? renderSuggestion : (entry) => get(entry, this.props.field, "");

		return (
			<Autosuggest
				suggestions={visibleSuggestions}
				onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
				onSuggestionSelected={this.handleSuggestionSelected}
				onSuggestionHighlighted={this.handleSuggestionHighlighted}
				getSuggestionValue={(entry) => get(entry, this.props.field, "")}
				renderSuggestion={(entry) => <div className={colorFunc(entry)} style={{zIndex: 100}}>{renderSuggestFunc(entry)}</div>}
				highlightFirstSuggestion={true}
				inputProps={inputProps}
				focusInputOnSuggestionClick={true}
			/>
		);
	}
}

SearchInput.defaultProps = {
	clearInputOnSelect: false
};

SearchInput.propTypes = {
	field: PropTypes.string.isRequired,
	onSelectItem: PropTypes.func.isRequired,
	apiConfig: PropTypes.object.isRequired,
	mandatory: PropTypes.bool,
	clearInputOnSelect: PropTypes.bool,
	source: PropTypes.object,
	filterCallback: PropTypes.func,
	renderSuggestion: PropTypes.func,
	colorizeCallback: PropTypes.func,
	handleEmptyValue: PropTypes.func
};

SearchInput.defaultProps = {
	mandatory: false
};

export default SearchInput;