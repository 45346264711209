import { inject, observer } from 'mobx-react';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import Spinner from "../../common/Spinner";
import IngredientRow from "./IngredientRow";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	InputGroup,
	Table,
	Col,
	Input, Row
} from 'reactstrap';
import IngredientsModal from "./IngredientsModal";
import FocusLock from "react-focus-lock";

@inject("ingredientSearchStore")
@inject("ingredientStore")
@observer
class IngredientsOverview extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			showRecipeModal: false,
			currentIngredient: null
		};
	}

	@autobind
	toggleModal() {
		this.setState({showModal: !this.state.showModal});
	}

	@autobind
	toggleRecipeModal() {
		this.setState({showRecipeModal: !this.state.showRecipeModal});
	}

	@autobind
	handleIngredientIconClick(e, ingredient) {
		e.preventDefault();
		const { ingredientStore } = this.props;
		ingredientStore.instantiateCurrentIngredient(ingredient);
		this.toggleModal();
	}

	@autobind
	handleShowAllInUse() {
		const { ingredientSearchStore } = this.props;
		const onlyShowInUse = (ingredient) => ingredient.inUse;
		ingredientSearchStore.getAllIngredients(onlyShowInUse);
	}

	@autobind
	handleShowAllIngredients() {
		const { ingredientSearchStore } = this.props;
		ingredientSearchStore.getAllIngredients();
	}

	@autobind
	handleAddNewIngredient(e) {
		e.preventDefault();
		const { ingredientStore } = this.props;
		ingredientStore.clear();
		this.toggleModal();
	}

	@autobind
	setIngredient(ingredient) {
		const { ingredientSearchStore } = this.props;
		ingredientSearchStore.replaceIngredient(ingredient);
	}

	@autobind
	listRecipes(e, ingredient) {
		e.preventDefault();
		this.setState({currentIngredient: ingredient});
		const { ingredientSearchStore } = this.props;
		ingredientSearchStore.getRecipesWithIngredientId(ingredient.id);
		this.toggleRecipeModal();
	}


	@autobind
	handleChange(e) {
		const {ingredientSearchStore} = this.props;
		ingredientSearchStore.search(e.target.value);
	}

	render() {
		const {searchResults, recipeResults, loading} = this.props.ingredientSearchStore;
		let ingredientName = this.state.currentIngredient ? this.state.currentIngredient.singular : "valgt ingrediense";

		return (
			<div style={{maxWidth: "1200px"}} className="ml-auto mr-auto">
					<Col className={"mb-3"}>
						<div className="clearfix">
							<InputGroup className="mb-2">
								<Input autoFocus onChange={this.handleChange} placeholder={"Skriv noen søkeord eller artikkelnr - eks. 'Tine ost' eller '6025'"} />
							</InputGroup>
							<Button color="primary" className="float-right" onClick={this.handleShowAllIngredients}>Vis alle ingredienser</Button>
							<Button color="primary" className="float-right mr-1" onClick={this.handleShowAllInUse}>Vis ingredienser i bruk</Button>
							<Button color="primary" className="float-left" onClick={this.handleAddNewIngredient}>Opprett ingrediens</Button>
						</div>
					</Col>
					<Col className={"mb-3"}>
						{searchResults.length > 0 &&
							<Table striped hover size="sm" className="text-left">
								<thead>
									<tr>
										<th></th>
										<th></th>
										<th>Singular</th>
										<th>Plural</th>
										<th>TINE Artikkelnr</th>
										<th>Antall oppskrifter</th>
									</tr>
								</thead>
								<tbody>
									{searchResults.map((ingr, idx) =>
										<IngredientRow listCallback={this.listRecipes} editCallback={this.handleIngredientIconClick}
										               ingredient={ingr} key={idx}>{ingr.singular}</IngredientRow>
									)}
								</tbody>
							</Table>
						}
					</Col>

					<Spinner enabled={loading} />

					<IngredientsModal isOpen={this.state.showModal} toggle={this.toggleModal} setIngredientCallback={this.setIngredient}/>

					<Modal isOpen={this.state.showRecipeModal} toggle={this.toggleRecipeModal} autoFocus={false} fade={false}>
						<ModalHeader toggle={this.toggleRecipeModal}>Oppskrifter med {ingredientName}</ModalHeader>
						<FocusLock disabled={!this.state.showRecipeModal}>
							<ModalBody>
								<ul>
									{recipeResults && recipeResults.map((r, idx) =>
										<li key={idx}><a className='external-link' target="_blank" href={`/recipe/${r.recipeId}`}>{r.displayName}</a></li>
									)}
								</ul>
							</ModalBody>
							<Spinner enabled={loading} />
							<ModalFooter>
								<Button color="primary" onClick={this.toggleRecipeModal}>Lukk</Button>
							</ModalFooter>
						</FocusLock>
					</Modal>
			</div>
		);
	}
}

export default IngredientsOverview;
