import { observable, action } from 'mobx';
import {RECIPE_API} from "../utils/consts";
import axios from 'axios';
import snackbarStore from './SnackbarStore';


class SearchStore {
	@observable searchResults = [];
	@observable searchFieldContent = "";
	@observable searchTermForResults = "";
	@observable searching = false;

	@action
	search(limit = 100, searchAllFields = false) {

		// Don't clear results and show "Searching..." unless it takes more than 150 ms
		this.searchTimer = setTimeout(() => {
			this.searchResults = [];
			this.searching = true;
		}, 150);

		const params = {
			terms: this.searchFieldContent,
			limit: limit
		};

		let path = `${RECIPE_API}/recipes/search`;

		if(searchAllFields) {
			path += "/allFields";
		}

		return axios.get(path, {params})
			.then((r) => this.parseSearchResult(r))
			.catch(this.handleError);
	}

	@action.bound
	parseSearchResult(response) {
		clearTimeout(this.searchTimer);
		this.searching = false;
		this.searchTermForResults = this.searchFieldContent;
		this.searchResults = response.data;
		return Promise.resolve();
	}

	@action
	handleError(error) {
		console.error(error);
		snackbarStore.addSnack("ERROR", "Noe gikk galt ved søk... sjekk konsollen for mer informasjon");
	}

	@action.bound
	clear() {
		this.searchResults = [];
		this.searchTermForResults = "";
		this.searchFieldContent = "";
		this.searching = false;
	}

}

const searchStore = new SearchStore();

export default searchStore;