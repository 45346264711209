import React from 'react';
import { observer } from 'mobx-react';

@observer
class FocusIndicator extends React.Component {

	render() {
		const { image } = this.props;
		const { focusX, focusY } = image;

		return (
			<React.Fragment>
				<div className="line vertical" style={{ left: (focusX * 100) + '%' }} />
				<div className="line horizontal" style={{ top: (focusY * 100) + '%'}} />
			</React.Fragment>
		);
	}
}

export default FocusIndicator;