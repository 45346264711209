import {inject, observer} from "mobx-react";
import React from 'react';
import autobind from 'autobind-decorator';
import IngredientSearchInput from "../../common/IngredientSearchInput";
import {Link, withRouter} from "react-router-dom";
import {Form, FormGroup, Label, Button, Col} from 'reactstrap';
import classnames from 'classnames';
import Spinner from "../../common/Spinner";

@withRouter
@inject("ingredientSwapStore")
@inject("uiStore")
@observer
export default class IngredientSwapper extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { match, ingredientStore, ingredientSwapStore } = this.props;
		if(match && match.params.ingredientId) {
			const ingredientId = match.params.ingredientId;
			ingredientSwapStore.initializeSwap(ingredientId);
		}
	}

	@autobind
	defaultIngredientSelected(ingredient) {
		this.props.ingredientSwapStore.setDefaultToIngredient(ingredient);
	}

	@autobind
	overrideIngredientSelected(recipeId, ingredient) {
		this.props.ingredientSwapStore.setOverrideToIngredient(recipeId, ingredient);
	}

	@autobind
	onButtonClicked(e) {
		this.props.ingredientSwapStore.swapIngredient();
		// this.props.ingredientSwapStore._preparePostData();
	}

	render() {

		const {ingredientSwapStore, uiStore} = this.props;
		const {ingredientSwap, loading} = ingredientSwapStore;
		const {overrides} = ingredientSwap;
		const {allFieldsValid} = uiStore;

		return (
			<div style={{maxWidth: "1200px"}} className="ml-auto mr-auto">
				<Col className={"mb-3"}>
					<h3><span className="content-title">Velg ny ingrediens for {ingredientSwap.fromIngredient ? ingredientSwap.fromIngredient.displayName : ""}</span></h3>
					<p className="small">Ingrediensen blir markert som ikke i bruk ved bytte.</p>
					<Form>
						<FormGroup row>
							<Label sm={6} for="ingredientDropdown">Hvis ingen annen velges, brukes</Label>
							<Col sm={6} >
								<IngredientSearchInput
									id="ingredientDropdown"
									ingredient={ingredientSwap.toIngredient}
									value = {ingredientSwap.toIngredient.displayName}
									onSelectItem={this.defaultIngredientSelected}
									mandatory={true}
									tabIndex={10}
								/>
							</Col>
						</FormGroup>

						<Spinner enabled={loading} />

						{overrides.size > 0 &&
							<div>
								<hr/>
								<h5><span className="content-title">Oppskrifter med {ingredientSwap.fromIngredient ? ingredientSwap.fromIngredient.displayName : ""}</span></h5>
								<p className="small">Erstattningsingrediens kan overstyres per oppskrift.</p>
							</div>
						}

						{ overrides.size > 0 && [...overrides].map( (entry, index) =>
							<FormGroup key={index} row>
								<Label sm={6} for={"recipeDropdown_" + index} key={"recipeDropdownLabel_" + index}>
									<a className={classnames({'text-black-50': !entry[1].id})} tabIndex={-1} href={`/recipe/${entry[0].recipeId}`} target="_blank">
										{entry[0].displayName}
									</a>
								</Label>
								<Col key={"col_" + index} sm={6}>
									<IngredientSearchInput
										id={"recipeDropdown_" + index}
										ingredient={entry[1]}
										value={entry[1].displayName}
										onSelectItem={(ingr) => this.overrideIngredientSelected(entry[0], ingr)}
										key={"recipeDropdown_" + index}
										tabIndex={10 + index}
									/>
								</Col>
							</FormGroup>
						)}
					</Form>
					<Button color="primary" className="float-right" disabled={!allFieldsValid} onClick={this.onButtonClicked} tabIndex={1000}>Bytt ingrediens</Button>
				</Col>
			</div>
		);
	}
}


