import React  from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { inject } from "mobx-react";

import PrivateRoute from './PrivateRoute';

import Authentication from './pages/Authentication';
import Editor from './pages/editor/Editor';
import Search from './pages/search/Search';
import NavigationBar from "./NavigationBar";
import Snackbar from './pages/editor/Snackbar';
import authStore from "./stores/AuthenticationStore";
import Spinner from './common/Spinner';

import {STORAGE_KEY} from "./utils/consts";
import BulkPublishMeny from "./pages/bulkpublish/BulkPublishMeny";
import IngredientsOverview from "./pages/ingredients/IngredientsOverview";
import IngredientSwapper from "./pages/ingredients/IngredientSwapper";

@inject("recipeStore")
@inject("authStore")
@observer
class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
	}

	componentDidMount() {
		const authStore = this.props.authStore;
		if(localStorage.getItem(STORAGE_KEY) !== null) {
			authStore.verifyLoggedIn(localStorage.getItem(STORAGE_KEY));
		} else {
			authStore.authInited = true;
		}
	}

	@autobind
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}


	render() {
		const authStore = this.props.authStore;
		if(!authStore.authInited) {
			return <Spinner />;
		}

		return (
			<Router>
				<React.Fragment>
					<NavigationBar/>
					<Route path="/authentication" component={Authentication} />
					<PrivateRoute exact path="/" component={Search} />
					<PrivateRoute path="/recipe/:recipeId/:tab?" component={Editor} />
					<PrivateRoute exact path="/bulk-publish-meny" component={BulkPublishMeny} />
					<PrivateRoute exact path="/ingredients" component={IngredientsOverview} />
					<PrivateRoute exact path="/ingredients/swap/:ingredientId" component={IngredientSwapper} />
					<Snackbar/>
				</React.Fragment>
			</Router>
		);
	}
}

export default App;
