/* global cloudinary */
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { constructImageUrl } from '../../utils/mediaUtils';
import PropTypes from "prop-types";
import {Button, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { MdRestaurantMenu } from 'react-icons/md';
import SyncedInput from "../../common/SyncedInput";
import FocusLock from 'react-focus-lock';
import Thumbnail from "./Thumbnail";
import SearchInput from "../../common/SearchInput";
import {RECIPE_API} from "../../utils/consts";

@observer
class RecipeSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	@autobind
	handleResultSelected(result) {
		const { recipe } = this.props;
		if(recipe) {
			recipe.recipeId = result.recipeId;
			recipe.imageUrl = constructImageUrl(result.imageUrl, 200);
		}
	}

	@autobind
	toggleModal() {
		this.setState({showModal: !this.state.showModal});
	}

	render() {

		const { showModal, mediaIsLoading } = this.state;
		const {recipe, onDeleteCallback, showIcon, bigIcon = false} = this.props;

		const apiConfig = {
			endpoint: `${RECIPE_API}/recipes/search`,
			queryParams: {
				searchKey: "terms",
				limit: 30
			}
		};

		return (
			<React.Fragment>
				{!showIcon && recipe.recipeId &&
					<Thumbnail onDeleteCallback={onDeleteCallback} onClickCallback={this.toggleModal} thumbnailUrl={recipe.imageUrl} />
				||
					<MdRestaurantMenu onClick={this.toggleModal} color={recipe.recipeId ? "#007bff" : "#212529"} className={(bigIcon ? "media-icon" : "media-icon-small") + " clickable"}/>
				}
				<Modal isOpen={showModal} toggle={this.toggleModal} autoFocus={false} className="media-modal" fade={false}>
					<ModalHeader toggle={this.toggleModal}>Rediger oppskriftskort {recipe.displayName ? " for " + recipe.displayName : ""} </ModalHeader>
					<FocusLock disabled={!showModal}>
						<ModalBody>
							<div className="media-modal-grid">
								<div className="media-modal-grid-title mb-1">
									<InputGroup>
										<InputGroupAddon className="media-modal-input-text" addonType="prepend">Oppskrift</InputGroupAddon>
										<SearchInput tabIndex="2"
										             field="displayName"
										             id="recipeSearch"
										             onSelectItem={this.handleResultSelected}
										             apiConfig={apiConfig}
										             placeholder="Søk og velg oppskrift" />

									</InputGroup>
								</div>
								<div className="media-modal-grid-url">
									<InputGroup>
										<InputGroupAddon className="media-modal-input-text" addonType="prepend">UUID</InputGroupAddon>
										<SyncedInput tabIndex="3" id="recipeId" source={recipe}/>
									</InputGroup>
								</div>
								<div className="media-modal-grid-thumbnail ml-1">
									{recipe.recipeId &&
										<Thumbnail onDeleteCallback={onDeleteCallback} onClickCallback={this.handleClick} thumbnailUrl={recipe.imageUrl} />
									||
										<MdRestaurantMenu color={recipe.recipeId ? "#007bff" : "black"} className="media-icon-small"/>
									}
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggleModal}>Lukk</Button>
						</ModalFooter>
					</FocusLock>
				</Modal>
			</React.Fragment>
		);
	}
}

RecipeSelector.propTypes = {
	recipe: PropTypes.object,
	onDeleteCallback: PropTypes.func,
	showIcon: PropTypes.bool
};

export default RecipeSelector;