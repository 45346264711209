import React, {Fragment} from "react";
import {DropdownItem, DropdownMenu, InputGroupButtonDropdown, DropdownToggle} from 'reactstrap';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import {inject, observer} from "mobx-react";

@inject("enonicStore")
@observer
class PathDropdown extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false
		}
	}

	@autobind
	toggleDropdown() {
		this.setState({showDropdown: !this.state.showDropdown});
	}

	@autobind
	handlePathClicked(e, path) {
		const { enonicStore } = this.props;
		enonicStore.setPath(path);
		this.props.onLockClickedCallback();
	}

	render() {
		const { enonicStore, disabled } = this.props;
		const path = (enonicStore.enonicRecipe.path && enonicStore.pathWithoutSlug !== "/") ? enonicStore.pathWithoutSlug : "Velg kategori";

		return (
			<Fragment>
				<InputGroupButtonDropdown className="col-sm-6 p-0" addonType="append" isOpen={this.state.showDropdown} toggle={this.toggleDropdown}>
					<DropdownToggle tabIndex="-1" disabled={disabled} caret className="col-sm-12 p-0 pl-2 text-left" style={{zIndex: 1}}>
						{path}
					</DropdownToggle>
					{enonicStore.categories &&
					<DropdownMenu>
						{enonicStore.categories.map((path, index) =>
							<DropdownItem
								key={path}
								onClick={(e) => this.handlePathClicked(e, path)}
							>
								{path}
							</DropdownItem>
						)
						}
					</DropdownMenu>
					}
				</InputGroupButtonDropdown>
			</Fragment>
		);
	}

}

PathDropdown.propTypes = {
	disabled: PropTypes.bool,
	onLockClickedCallback: PropTypes.func
};


export default PathDropdown;