import React from "react";
import { observer } from 'mobx-react';
import {MdRemoveCircleOutline, MdAddCircleOutline, MdErrorOutline} from 'react-icons/md';
import PropTypes from "prop-types";

@observer
class BulkItem extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const { item } = this.props;

		const color = item.active === true // status is 'active = true'.
						? "lightgreen"
						: (item.active === false
							? "lightyellow" // status is 'active = false'
							: "white" // recipe has not been submitted to Meny
						);

		return (
			<div className="bulk-item-container" style={{background: color}}>
				{ item.status === "DELETED" && <MdRemoveCircleOutline className="bulk-item-icon" /> }
				{ item.status === "SAVED" && <MdAddCircleOutline className="bulk-item-icon" /> }
				{ item.status === "ERROR" && <MdErrorOutline className="bulk-item-icon" style={{color: "red"}}  /> }
				<span className="bulk-item">{item.url}</span>
			</div>
		);
	}

}

BulkItem.propTypes = {
	menyStore: PropTypes.object,
	item: PropTypes.object
};

export default BulkItem;