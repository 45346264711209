/* global cloudinary */
import React, {Fragment} from 'react';
import autobind from 'autobind-decorator';
import {inject, observer} from 'mobx-react';
import {constructImageUrl, constructImageUrlWithFocus} from '../../utils/mediaUtils';
import PropTypes from "prop-types";
import {Button, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import FocusIndicator from './FocusIndicator';
import { MdImage } from 'react-icons/md';
import SyncedInput from "../../common/SyncedInput";
import FocusLock from 'react-focus-lock';
import Thumbnail from "./Thumbnail";
import {KeyBindingUtil} from "draft-js";
import {CLOUDINARY_CONFIG} from "../../utils/consts";

const { hasCommandModifier } = KeyBindingUtil;

@inject("uiStore")
@inject("recipeStore")
@observer
class ImageSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};

		this.uploadWidget = cloudinary.createUploadWidget({
			cloudName: CLOUDINARY_CONFIG.cloud_name,
			uploadPreset: CLOUDINARY_CONFIG.upload_preset,
			multiple: false,
			sources: [ "local", "url"],
			clientAllowedFormats: ["jpg", "jpeg", "png"],

		}, this.imageUploaded);
	}

	componentDidUpdate() {
		const {mandatory} = this.props;
		if (mandatory) {
			this.updateMandatoryStatus();
		}
	}

	componentWillUnmount() {
		const {mandatory, recipeStore, id, uiStore} = this.props;
		if (mandatory) {
			uiStore.removeMandatoryFieldComponent(recipeStore.currentRecipe, id);
		}
	}

	@autobind
	updateMandatoryStatus() {
		const {image, id, uiStore, recipeStore} = this.props;
		if (image.url) {
			uiStore.removeMandatoryFieldComponent(recipeStore.currentRecipe, id);
		} else {
			uiStore.addMandatoryFieldComponent(recipeStore.currentRecipe, id);
		}
	}

	@autobind
	handleImageSelect() {
		this.uploadWidget.open();
	}

	@autobind
	handleFocusSelect(e) {
        if ((e.target.x || e.target.y) && (e.target.x !== 0 || e.target.y !==0)) {
			this.props.image.focusX = (e.clientX - e.target.x) / e.target.clientWidth;
            this.props.image.focusY = (e.clientY - e.target.y) / e.target.clientHeight;
        } else {
            let {imagePosX, imagePosY} = ImageSelector.getImagePosition(e);
            this.props.image.focusX = (e.pageX - imagePosX) / e.target.clientWidth;
            this.props.image.focusY = (e.pageY - imagePosY) / e.target.clientHeight;
        }
	}

    static getImagePosition(e) {
        let imagePosX = 0, imagePosY = 0;
        let image = e.target;
        for (image; image = image.offsetParent;) {
            imagePosX += image.offsetLeft;
            imagePosY += image.offsetTop;
        }
        return {imagePosX, imagePosY};
    }

    @autobind
	handleClick(e) {
		e.preventDefault();
		if(hasCommandModifier(e) && this.props.image.url) { // check if ctrl + click
			this.handleFocusSelect(e);
		} else {
			this.handleImageSelect(e);
		}
	}

	@autobind
	imageUploaded(error, result) {
		if(error) {
			if(error.message !== "User closed widget") {
				alert(JSON.stringify(error, null, 2));
			}
			return;
		}

		if(result.event === "success") {
			const upload = result.info;
			const { image } = this.props;
			const transformString = 'w_%width%,h_%height%,c_fill';
			image.url = `https://recipe-cdn.azureedge.net/tine-sa-oppskriftsbasen/image/upload/${transformString}/${upload.public_id}.${upload.format}`;
			image.sourceWidth = upload.width;
			image.sourceHeight = upload.height;
		}
	}

	@autobind
	toggleModal() {
		this.setState({showModal: !this.state.showModal});
	}

	render() {
		const { showModal } = this.state;
		const { image, onDeleteCallback, showIcon, bigIcon = false}= this.props;

		return (
			<React.Fragment>

				{!showIcon && image.url &&
					<Thumbnail onDeleteCallback={onDeleteCallback} onClickCallback={this.toggleModal} thumbnailUrl={constructImageUrlWithFocus(image, 600)} />
				||
					<MdImage onClick={this.toggleModal} color={image.url ? "#007bff" : "#212529"} className={(bigIcon ? "media-icon" : "media-icon-small") + " clickable"}/>
				}

				<Modal isOpen={showModal} toggle={this.toggleModal} autoFocus={false} className="media-modal" fade={false}>
					<ModalHeader toggle={this.toggleModal}>Rediger bilde</ModalHeader>
					<FocusLock disabled={!showModal}>
						<ModalBody>
						<div className="media-modal-grid">
							<div className="media-modal-grid-title mb-1">
								<InputGroup>
									<InputGroupAddon className="media-modal-input-text" addonType="prepend">Tittel</InputGroupAddon>
									<SyncedInput id="title" source={image} mandatory={true} />
								</InputGroup>
							</div>
							<div className="media-modal-grid-description m-0 p-0">
							</div>
							<div className="media-modal-grid-url">
								<InputGroup>
									<InputGroupAddon className="media-modal-input-text" addonType="prepend">Lenke til</InputGroupAddon>
									<SyncedInput id="targetUrl" source={image} placeholder="(valgfritt)"/>
								</InputGroup>
							</div>
							<div className="media-modal-grid-thumbnail ml-1">
								{image.url &&
									<div>
										<Thumbnail onDeleteCallback={onDeleteCallback} onClickCallback={this.handleClick} thumbnailUrl={constructImageUrl(image.url, 600, 0)} >
											<FocusIndicator image={image} />
										</Thumbnail>
										<span className="small">Klikk for å velge bilde. Ctrl + klikk for å sette fokus</span>
									</div>
								||
									<div>
										<MdImage onClick={this.handleClick} className="media-icon clickable"/>
										<br/>
										<span className="small">Klikk for å velge bilde.</span>
									</div>
								}

							</div>
						</div>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggleModal}>Lukk</Button>
						</ModalFooter>
					</FocusLock>
				</Modal>

			</React.Fragment>

		);
	}
}

ImageSelector.propTypes = {
	image: PropTypes.object,
	onDeleteCallback: PropTypes.func,
	showIcon: PropTypes.bool,
	mandatory: PropTypes.bool,
	id: PropTypes.string
};

export default ImageSelector;
