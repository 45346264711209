import {action, observable} from 'mobx';
import axios from 'axios';
import {RECIPE_API} from '../utils/consts';
import snackbarStore from './SnackbarStore';

import enonicStore from "./EnonicStore";
import recipeStore from "./RecipeStore";

class SiteStore {

    @observable contributors = [];
    @observable loading = false;

    @action
    initContributors() {
        if (this.contributors.length === 0) {
            this.loading = true;
            return axios.get(`${RECIPE_API}/site/contributors`)
                .then(response => {
                    this.contributors = response.data.map(data => new Contributor(data)).sort((a, b) => a.order - b.order);
                    return Promise.resolve();
                })
                .then(() => this.initializeCategories())
                .catch(this.handleError)
                .finally(() => this.loading = false);
        }
    }

    initializeCategories() {
        if (!enonicStore.site) {
            enonicStore.site = this.getDefaultContributor();
            recipeStore.currentRecipe.contributor = enonicStore.site;
            return enonicStore.fetchCategories();
        } else {
            return Promise.resolve();
        }
    }

    @action
    getDefaultContributor() {
        if(this.contributors.length === 0) {
            console.warn("Not yet any contributors in list...");
            return null;
        }

        const result = this.contributors.find(c => c.default);
        console.log("Default contributor: ", result);
        if (result) {
            return result;
        } else {
            return this.contributors[0];
        }
    }

    @action
    getContributors() {
        return [...this.contributors].map(c => new Contributor(c))
    }

    @action
    handleError(error) {
        if (error.response && error.response.data) {
            console.error(JSON.stringify(error.response || error, null, 2));
            let code = error.response.status + ": " + error.response.statusText;
            let msg = error.response.data.error || error.response.data.errors || error.response.data.message || "Ukjent feil - se konsoll for feilmelding";
            snackbarStore.addSnack(code, msg, true, 5000);
        } else {
            console.trace(error);
            snackbarStore.addSnack("ERROR", error.response || "Ukjent feil - se konsoll for feilmelding", true, 5000);
        }
    }
}

class Contributor {
    @observable order = 0;
    @observable default = false;
    @observable name = "";
    @observable rootpath = "";
    @observable urlprefix = "";

    constructor(data){
        this.order = data.order;
        this.default = data.default;
        this.name = data.name;
        this.rootpath = data.rootpath;
        this.urlprefix = data.urlprefix;
    }
}

const siteStore = new SiteStore();
export default siteStore;
