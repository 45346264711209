const RECIPE_API = "https://recipedb-test.tine.no";
const XP_API = "https://xp7-test.tine.no/site/tine/draft";
const XP_API_KEY = "5xLqBM4t9YhJUwWdqcXmpTS4EuQgmZ5j";
const GOOGLE_API_KEY = "AIzaSyDhXVhD8BbGZvVrptwxkEFd4MOqgCX5nhc";
const GOOGLE_YOUTUBE_API = "https://www.googleapis.com/youtube/v3";

const CONFIRM_ON_NAVIGATE = true;

const MENY_API = {
	endpoint: "https://platform-rest-preprod.ngdata.no/api/recipes2/1300",
	apiKey: "83890c90-d078-11e8-a2ff-bf262e049772",
	siteKey: "TK"
};

const CLOUDINARY_CONFIG = {
	cloud_name: 'tine-sa-oppskriftsbasen',
	upload_preset: 'tor_upload_test',
	multiple: false
};

export {
	RECIPE_API,
	XP_API,
	XP_API_KEY,
	GOOGLE_API_KEY,
	GOOGLE_YOUTUBE_API,
	MENY_API,
	CLOUDINARY_CONFIG,
	CONFIRM_ON_NAVIGATE
};
