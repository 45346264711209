import React from "react";
import classnames from 'classnames';
import {Container, NavItem, NavLink ,Nav, TabPane, TabContent} from 'reactstrap';
import {inject, observer} from "mobx-react";
import PublishEnonic from "./PublishEnonic";
import PublishMeny from "./PublishMeny";
import InlineSVG from 'svg-inline-react';

@inject("recipeStore")
@observer
class PublishTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: "enonic"
		}
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	render() {

		const { currentRecipe } = this.props.recipeStore;

		return (
			<Container className="mt-4">
				<Nav tabs className="recipe-tabs justify-content-end">
					<h3 style={{position: "absolute", left: "10px"}}><span className="content-title">Klargjøring</span> | {currentRecipe.displayName}</h3>
					<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === 'enonic' }, "pl-2 pr-2 pt-1 pb-1")}
							onClick={() => { this.toggle('enonic'); }}
						>
							<div style={{height: "30px", width: "100px"}}>
								<InlineSVG src={require("../../images/enonic.svg")} />
							</div>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === 'meny' }, "pl-2 pr-2 pt-1 pb-1")}
							onClick={() => { this.toggle('meny'); }}

						>
							<div style={{height: "30px"}}>
								<img src={require('../../images/meny-logo-30.png')} />
							</div>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
					<TabPane tabId="enonic">
						<PublishEnonic />
					</TabPane>
					<TabPane tabId="meny">
						<PublishMeny />
					</TabPane>
				</TabContent>
			</Container>
		);	}

}

PublishTab.propTypes = {

};


export default PublishTab;