import React from 'react';
import { Col, FormGroup, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import { SyncedInput } from ".";

/* Lets us easily specify more fields for the details pane. Basically just sets up different bootstrap tags to
 * create the required structure for that particular field. A few of these destructuring calls include unused
 * keys just to avoid them being included in the "rest" variable.
 */
class Field extends React.Component {

	renderPlainInput() {
		const { source, id, unit, inline, ...rest } = this.props;
		return <SyncedInput id={id} source={source} {...rest} />;
	}

	renderInputWithUnit() {
		const { unit } = this.props;
		return (
			<InputGroup>
				{this.renderPlainInput()}
				<InputGroupAddon addonType="append">{unit}</InputGroupAddon>
			</InputGroup>
		);
	}

	renderFieldVariation() {
		if(this.props.unit) {
			return this.renderInputWithUnit();
		} else {
			return this.renderPlainInput();
		}
	}

	render() {
		const { id, label, inline = null } = this.props;

		return (
			<FormGroup row={inline}>
				<Label for={id} sm={inline ? 3 : null}>{label}</Label>
				{ inline ? <Col sm={9}>{this.renderFieldVariation()}</Col> : this.renderFieldVariation() }
			</FormGroup>
		);
	}
}

export default Field;