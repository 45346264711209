import React from "react";
import PropTypes from "prop-types";
import autobind from 'autobind-decorator';
import { FormGroup, Label } from 'reactstrap';
import { inject, observer } from "mobx-react";
import { SearchInput } from '../../common';
import {XP_API, XP_API_KEY } from "../../utils/consts";

@inject("enonicStore")
@observer
class Tag extends React.Component {

	@autobind
	handleClick(e) {
        e.preventDefault();
		const { enonicStore } = this.props;
		enonicStore.removeTag(this.props.tag);
    }

	render() {
		const { tag } = this.props;
		return (
			<div className="tag-item" onClick={this.handleClick}>{tag}</div>
		);
	}
}

Tag.propTypes = {
	tag: PropTypes.string
};

@inject("enonicStore")
@observer
class TagBox extends React.Component {

	render() {
		const tags = this.props.enonicStore.tags;
		return <div className="tag-box">
			{tags.map((tag, idx) =>
				<Tag tag={tag} key={idx} />
			)}
		</div>
	}
}

@inject("enonicStore")
@observer
class TagsSelector extends React.Component {

	@autobind
	updateTagBox(tag) {
		const { enonicStore } = this.props;
		enonicStore.addTag(tag);
	}

	@autobind
	isNotAlreadyInList(entry) {
		const tagList = this.props.enonicStore.tags.slice();
		return tagList.indexOf(entry.displayName) === -1;
	}

	render() {

		const {enonicStore} = this.props;

		const apiConfig = {
			endpoint: `${XP_API}` + "/_/service/no.tine.recipepublish/tags",
			queryParams: {
				searchKey: "q",
				apikey: `${XP_API_KEY}`,
				rootPath:  enonicStore.site && enonicStore.site.rootpath
			},
			dataNode : "tags"
		};

		return (enonicStore.site ?
			<div className='tag-container'>
				<FormGroup >
					<SearchInput field="displayName"
					             clearInputOnSelect={true}
					             id="tagSearch"
					             onSelectItem={this.updateTagBox}
					             filterCallback={this.isNotAlreadyInList}
					             apiConfig={apiConfig}
					             placeholder="Søk og velg tags"
					/>
				</FormGroup>
				<TagBox />
				<span className="small">Klikk på boblene for å fjerne tags.</span>
			</div>
				: null
		)
	}
}

TagsSelector.propTypes = {
	enonicStore: PropTypes.object
};

export default TagsSelector;