import React from 'react';
import {Col, Container, Form, FormGroup, Label, Row} from 'reactstrap';
import {inject, observer} from 'mobx-react';
import {Field, SyncedInput} from "../../common";
import autobind from "autobind-decorator";
import MediaSelector from "../selectors/MediaSelector";
import {convertText} from "../../common/Utils";

@inject("recipeStore")
@inject("siteStore")
@inject("enonicStore")
@observer
class OverviewTab extends React.Component {

	constructor(props) {
		super(props);
	}

	@autobind
	handleOnBlurTitle(e) {
		const {enonicStore, recipeStore} = this.props;
		const { image, displayName } = recipeStore.currentRecipe;

		if(enonicStore.publishedStatus === "UNPUBLISHED" ) { // set default slug for newly created recipes
			enonicStore.urlSlug = convertText(displayName);
		}

		if(!image.title) { // if empty, set default image title
			image.title = displayName;
		}

	}

	@autobind
	deleteImage(e) {
		this.props.recipeStore.removeImage();
	}

	@autobind
	deleteVideo(e) {
		this.props.recipeStore.removeVideo();
	}

	@autobind
	handleContributorChanged(e) {
		const { enonicStore, recipeStore } = this.props;
		enonicStore.setSite(e.target.value);
		recipeStore.currentRecipe.contributor = enonicStore.site;
	}

	@autobind
	onDescriptionChange(e) {
		const {recipeStore} = this.props;
		const {currentRecipe} = recipeStore;
		currentRecipe.metaDescriptionPlaceholder = currentRecipe.description;
	}

	render() {
		const { recipeStore } = this.props;
		const recipe = recipeStore.currentRecipe;

		return (
			<Container className="mt-4">
				<h3><span className="content-title">Om oppskriften</span></h3>
				<Row>
					<Col className="col-sm-8">

						<Form>
							<Field source={recipe} id="displayName" label="Tittel" mandatory={true} onBlur={this.handleOnBlurTitle} />
							<Field source={recipe} id="description" rich={true} mandatory={true} label="Beskrivelse" type="textarea" rows={4} onChangeCallback={this.onDescriptionChange} />

							<Field source={recipe} id="shortName" label="Kort tittel" placeholder={recipe.shortNamePlaceholder} />
							<Field source={recipe} id="metaDescription" rich={true} label="Meta description" charCounter={true} charCounterMax={150} type="textarea" placeholder={recipe.metaDescriptionPlaceholder} rows={4} />

							<Field source={recipe} id="tip" rich={true} label="Legg til tips" type="textarea" rows={4} />
							<p className="small">Legg til lenker eller redigere markert tekst i tipsfeltet ved å taste <kbd>Ctrl + K</kbd> eller <kbd>Ctrl + klikk</kbd></p>

							<Row>
								<Col>
									<Field source={recipe} id="workTime" label="Tidsbruk" unit="min" type="number" />
								</Col>
								<Col>
									<Field source={recipe} id="cookingTime" label="Steketid" unit="min" type="number" />
								</Col>
							</Row>

							<Row>
								<Col>
									<Field source={recipe} id="portionLabel" type="select" label="Porsjoner eller antall">
										<option value="">Ikke valgt</option>
										<option value="stykker">Oppgitt i stykk</option>
										<option value="porsjoner">Oppgitt i porsjoner</option>
									</Field>
								</Col>
								<Col>
									<Field source={recipe} id="portions" label="Porsjoner" type="number"  />
								</Col>
							</Row>

							<Row>
								<Col>
									<Field source={recipe} id="difficulty" type="select" label="Vanskelig">
										<option value="easy">Lett</option>
										<option value="medium">Middels</option>
										<option value="difficult">Kompleks</option>
									</Field>
								</Col>
								<Col >
									<FormGroup check className="mb-3">
										<Label check >
											<SyncedInput type="checkbox" source={recipe} id="multipliable" />{' '}Porsjoner kan omregnes
										</Label>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<Field source={recipe} id="utensils" label="Utstyr" type="textarea" rows={2} />
								</Col>
							</Row>

							<br/>

							<br/>

						</Form>
					</Col>
					<Col className="col-sm-4">
						<MediaSelector media={recipe.image} bigIcon={true} onDeleteCallback={this.deleteImage} mandatory={true} id="recipe-image"/>
						<Field source={recipe} id="image.attribution" label="Fotokreditt"/>
						<hr/>
						<MediaSelector media={recipe.video} bigIcon={true} onDeleteCallback={this.deleteVideo} />
						<hr/>
						{this.props.siteStore.loading ? '' :
							<Field source={recipe} id="contributor.name" updateSource={false} type="select" label="Skrevet av" onChangeCallback={this.handleContributorChanged}>
								{this.props.siteStore.getContributors().map(s => <option key={s.name} value={s.name}>{s.name}</option>)}
							</Field>
						}
					</Col>
				</Row>
			</Container>

		);
	}

}

OverviewTab.propTypes = {

};


export default OverviewTab;
