import React from 'react';
import {Form, FormGroup, Label} from 'reactstrap';
import { KeyBindingUtil } from 'draft-js';
import { SyncedInput, SearchInput, OrderManagementUI } from '../../common';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import IngredientsModal from "../ingredients/IngredientsModal";
import { RECIPE_API } from "../../utils/consts";
import { FaPencilAlt, FaBars, FaMinus } from 'react-icons/fa';
import IngredientSearchInput from "../../common/IngredientSearchInput";
import {AlternativeIngredient} from "../../stores/IngredientStore";
import AlternativeIngredientPrLine from "./AlternativeIngredientPrLine";

const { hasCommandModifier } = KeyBindingUtil;

@inject("recipeStore")
@inject("uiStore")
@inject("ingredientStore")
@observer
class IngredientLine extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showHeading: false,
			focusHeading: false,
			showModal: false,
			onlyShowInUse: true,
			showAlternativeIngredients: false
		};
		this.headingInput = React.createRef();
	}

	componentWillMount() {
		const {line} = this.props;
		if(line.heading) {
			this.setState({showHeading: true});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.focusHeading) {
			this.headingInput.current.focus();
			this.setState({focusHeading: false});
		}
	}

	@autobind
	toggleShowHeading() {
		this.setState({
			showHeading: !this.state.showHeading,
			focusHeading: !this.state.focusHeading
		});
	}

	@autobind
	handleRemove() {
		const { line, uiStore, recipeStore } = this.props;

		uiStore.removeMandatoryFieldComponent(line, "unit");
		uiStore.removeMandatoryFieldComponent(line, "amount");
		recipeStore.removeIngredientLine(line.id);
	}

	@autobind
	handleChange() {
		const { recipeStore, isLast } = this.props;

		if(isLast && !this.isPlaceholderLine()) {
			recipeStore.addEmptyIngredientLine();
		}
	}

	@autobind
	updateIngrendient(selection) {
		this.props.line.changeIngredient(selection);
		this.handleChange();
	}

	@autobind
	addAlternativeIngredient(selection) {
		this.props.line.addAlternativeIngredient(new AlternativeIngredient({ingredient: selection, replacementTypes: []}));
		if (!this.state.showAlternativeIngredients) {
			this.setState({showAlternativeIngredients: true})
		}
		this.handleChange();
	}

	@autobind
	removeAlternativIngredient(selection) {
		this.props.line.removeAlternativeIngredient(selection);
		this.handleChange();
	}

	@autobind
	updateUnit(selection) {
		this.props.line.changeUnit(selection);
		this.handleChange();
	}

	isPlaceholderLine() {
		const { line } = this.props;
		return (line.amount === "" && line.ingredient === null && line.unit === null && line.postText === "" && line.preText === "");
	}

	@autobind
	handleKeyDown(e) {
		if(e.key === "Shift" && this.state.onlyShowInUse) {
			this.setState({
				onlyShowInUse: false
			});
			return;
		}

		if (e.keyCode === "H".charCodeAt(0) && hasCommandModifier(e)) { //  Ctrl + H
			e.preventDefault();

			const { recipeStore, isLast } = this.props;
			if(isLast) {
				recipeStore.addEmptyIngredientLine();
			}

			this.toggleShowHeading();
		}
	}
	@autobind
	handleKeyUp(e) {
		if(e.key === "Shift" && !this.state.onlyShowInUse) {
			this.setState({
				onlyShowInUse: true
			});
		}
	}

	@autobind
	toggleModal() {
		this.setState({showModal: !this.state.showModal});
	}

	@autobind
	handleIngredientIconClick(e) {
		const { line, ingredientStore } = this.props;
		ingredientStore.instantiateCurrentIngredient(line.ingredient);
		this.toggleModal();
	}

	@autobind
	handleDisplayAlternativeIngredients(e) {
		this.setState({showAlternativeIngredients: !this.state.showAlternativeIngredients})
	}

	@autobind
	filterIngredientSearchResult(ingredient) {
		const line = this.props.line;
		return !line.ingredient || (line.ingredient.id !== ingredient.id &&
			!line.alternatives.some(alt => alt.ingredient.id === ingredient.id));
	}

	@autobind
	setIngredient(data) {
		const { line } = this.props;
		line.changeIngredient(data);
	}

	render() {
		const { line, isLast  } = this.props;

		const unitApiConfig = {
			endpoint: `${RECIPE_API}` + "/units/search",
			queryParams: {
				searchKey: "term"
			}
		};

		// amount and unit becomes mandatory fields if there is an ingredient
		let mandatoryUnitAndAmount = !isLast && !!line.ingredientName && true;
		let headingDisplayState = (!isLast && this.state.showHeading) ? "initial" : "none";

		return (
			<div className="ingredientline-container">
				{this.props.isLast && <p className="mb-0">Ny ingredienslinje</p>}
				<Form>
					<SyncedInput className="bold-field" id="heading"  style={{display: headingDisplayState}} placeholder="Overskrift" source={line} innerRef={this.headingInput} onKeyDown={this.handleKeyDown} />
				</Form>
				<Form inline className="ingredientline mb-2" onSubmit={(e) => e.preventDefault()}>

					{!isLast && <OrderManagementUI handleRemove={this.handleRemove} />}

					<FormGroup className="w-10">
						<Label for="amount" hidden>Mengde</Label>
						<SyncedInput type="lax-number" className="w-100" source={line} id="amount"  mandatory={mandatoryUnitAndAmount} onChangeCallback={this.handleChange} onKeyDown={this.handleKeyDown} />
					</FormGroup>
					{' '}
					<FormGroup className="w-10">
						<Label for="unit" hidden>Enhet</Label>
						<SearchInput field="singular" className="w-100" value={line.unitName} source={line}
						             id="unit" mandatory={mandatoryUnitAndAmount} onSelectItem={this.updateUnit} apiConfig={unitApiConfig} onKeyDown={this.handleKeyDown} />
					</FormGroup>
					{' '}
					<FormGroup className="w-15">
						<Label for="preText" hidden>Tekst foran</Label>
						<SyncedInput type="text" className="w-100" source={line} id="preText" onChangeCallback={this.handleChange} onKeyDown={this.handleKeyDown} />
					</FormGroup>
					{' '}
					<FormGroup className="w-30">
						<Label for="ingredientSearch" hidden>Ingrediens</Label>
						{!isLast && line.ingredient && <span className="input-icon clickable" onClick={this.handleIngredientIconClick}><FaPencilAlt /></span>}
						<IngredientSearchInput
							id="ingredientSearch"
							ingredient={line.ingredient}
							value={line.ingredientName}
							onSelectItem={this.updateIngrendient}
						/>
					</FormGroup>
					{' '}
					<FormGroup className="w-20">
						<Label for="ingredientAltSearch" hidden>Alternative ingredients</Label>
						{!isLast && line.alternatives && line.alternatives.length !== 0 &&
							<span className="input-icon clickable" onClick={this.handleDisplayAlternativeIngredients}>
								{this.state.showAlternativeIngredients ? <FaMinus /> : <FaBars />}
							</span>
						}
						<IngredientSearchInput
							id="ingredientAltSearch"
							ingredient={line.ingredient}
							value=""
							onSelectItem={this.addAlternativeIngredient}
							clearInputOnSelect={true}
							filterCallback={this.filterIngredientSearchResult}
						/>
					</FormGroup>
					{' '}
					<FormGroup className="w-10">
						<Label for="postText" hidden>Tekst bak</Label>
						<SyncedInput type="text" className="w-100" source={line} id="postText" onChangeCallback={this.handleChange} onKeyDown={this.handleKeyDown} />
					</FormGroup>
					<FormGroup className="w-5">
						<Label for="omissible" hidden>Kan sl�yfes</Label>
						<SyncedInput type="checkbox" className="w-100" source={line} id="omissible" onChangeCallback={this.handleChange} onKeyDown={this.handleKeyDown} />
					</FormGroup>
				</Form>

				{this.state.showAlternativeIngredients && line.alternatives.map(line =>
					<AlternativeIngredientPrLine
						key={line.ingredient.id}
						line={line}
						handleRemove={() => this.removeAlternativIngredient(line)}
						filterIngredientSearchResult={this.filterIngredientSearchResult}
					/>
				)}

				<IngredientsModal isOpen={this.state.showModal} toggle={this.toggleModal} setIngredientCallback={this.setIngredient}/>
			</div>
		);
	}
}

IngredientLine.propTypes = {
	isLast: PropTypes.bool.isRequired,
	line: PropTypes.object
};

export default IngredientLine;
