import React, {Fragment} from "react";
import {Col, Row, InputGroup} from 'reactstrap';
import autobind from 'autobind-decorator';
import TagsSelector from "../editor/TagsSelector";
import {inject, observer} from "mobx-react";
import RelatedRecipes from "./RelatedRecipes";
import PathDropdown from "./PathDropdown";
import PathInput from "./PathInput";
import RelatedXpArticles from "./RelatedXpArticles";

@inject("recipeStore")
@inject("enonicStore")
@observer
class PublishEnonic extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			pathReadOnly: true
		}
	}

	@autobind
	handleLockClickedCallback(e) {
		this.setState({
			pathReadOnly: !this.state.pathReadOnly
		});
	}

	render() {
		const { recipeStore, enonicStore } = this.props;
		const recipe = recipeStore.currentRecipe;

		let linkDisplayState = (enonicStore.pathValid) ? "initial" : "none";
		let linkUrl = enonicStore.site && enonicStore.site.urlprefix + enonicStore.enonicRecipe.path;

		return (
			<Fragment>
				<Row className="pt-3">
					<Col className="col-sm-6">
						<h4>Relaterte oppskrifter</h4>
						<RelatedRecipes/>
					</Col>
					<Col className="col-sm-6">
						<h4>Tags</h4>
						<TagsSelector/>
					</Col>
				</Row>
				<Row>
					<Col className="col-sm-6">
						<h4>Relaterte artikler fra TINE Kjøkken</h4>
						<RelatedXpArticles/>
					</Col>
				</Row>
				<hr/>
				<div>
					<h4 className="d-inline pr-3">Url for oppskriften</h4>
					<div className="small d-inline"><a tabIndex="-1" className="small" target="_blank" style={{display: linkDisplayState}} href={linkUrl}>{linkUrl}</a></div>
				</div>

				<Row className="pt-3">
					<Col className="col-sm-12">
						<InputGroup>
							<PathDropdown disabled={this.state.pathReadOnly} onLockClickedCallback={this.handleLockClickedCallback} />
							<PathInput disabled={this.state.pathReadOnly} onLockClickedCallback={this.handleLockClickedCallback} />
						</InputGroup>
					</Col>
				</Row>
				<div className="small">Du kan endre oppskriftens url. Merk at dette kan påvirke ulike løsninger negativt.</div>
			</Fragment>
		);
	}

}

PublishEnonic.propTypes = {

};


export default PublishEnonic;