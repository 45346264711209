import React from "react";
import {Button, Col, Container, Row} from "reactstrap";
import {inject, observer} from "mobx-react";
import IngredientsHeader from "./IngredientsHeader";
import Ingredients from "./Ingredients";
import Steps from "../editor/Steps";
import {withRouter} from "react-router-dom";
import IngredientsModal from "../ingredients/IngredientsModal";
import autobind from "autobind-decorator";

@withRouter
@inject("recipeStore")
@observer
class DetailsTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	@autobind
	toggleModal() {
		this.setState({ showModal: !this.state.showModal });
	}

	render() {
		const { recipeStore } = this.props;
		const recipe = recipeStore.currentRecipe;
		const { showModal } = this.state;

		return (
			<Container className="mt-4">
				<h3><span className="content-title">Ingredienser og forklaring</span> | {recipe.displayName}</h3>
				<Row>
					<Col className="col-sm-12">
						<h5>Ingredienser</h5>
						<p className="small">Legg til overskrift ved å bruke hurtigkommandoen <kbd>Ctrl + H</kbd> når du står på første element som skal være under overskriften. Hold <kbd>shift</kbd> for å se ingredienser som ikke er i bruk lengre.</p>
						<IngredientsHeader />
						<Ingredients />
						<Button color="primary" onClick={this.toggleModal}>Opprett manglende ingrediens</Button>
						<IngredientsModal isOpen={showModal} toggle={this.toggleModal} />
						<h5 className="mt-4">Fremgangsmåte</h5>
						<p className="small">Legg til overskrift ved å bruke hurtigkommandoen <kbd>Ctrl + H</kbd> når du står på første element som skal være overskriften. Legg til lenker eller redigere markert tekst i tipsfeltet ved å taste <kbd>Ctrl + K</kbd> eller <kbd>Ctrl + klikk</kbd></p>
						<Steps />
					</Col>
				</Row>
			</Container>
		);
	}

}

DetailsTab.propTypes = {

};


export default DetailsTab;