import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import React from 'react';
import {FaPencilAlt} from "react-icons/fa";
import {MdPause} from 'react-icons/md';
import classnames from 'classnames';
import {Link} from "react-router-dom";

@inject("ingredientStore")
@observer
export default class IngredientRow extends React.Component {

	render() {
		const { ingredient, editCallback, listCallback } = this.props;

		let className = classnames({
			"text-secondary": !ingredient.inUse,
			"clickable": true
		});

		let rowStyle = {
			backgroundColor: (ingredient.status === "90" && ingredient.inUse) ? "salmon" : null
		};

		return (
			<tr className={className} style={rowStyle}>
				<td><FaPencilAlt className="pl-1 align-text-top clickable" onClick={e => editCallback(e, ingredient)} /></td>
				<td><Link to={`/ingredients/swap/${ingredient.id}`}><MdPause size="21px" color="black"/></Link></td>
				<td onClick={e => listCallback(e, ingredient)}>{ingredient.singular}</td>
				<td onClick={e => listCallback(e, ingredient)}>{ingredient.plural}</td>
				<td onClick={e => listCallback(e, ingredient)}>{ingredient.articleNo}</td>
				<td>{ingredient.usageCount}</td>
			</tr>
		);
	}
}

IngredientRow.propTypes = {
	ingredient: PropTypes.object,
	editCallback: PropTypes.func,
	listCallback: PropTypes.func
};
