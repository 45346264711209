import {action, observable} from "mobx";
import axios from "axios";
import {RECIPE_API} from "../utils/consts";
import {Recipe} from "./RecipeStore";
import recipeStore from "./RecipeStore";
import { diff as deepdiff } from 'deep-diff';

class RecipeDiffStore {
	recipeOne = {};
	recipeTwo = {};
	@observable loading = false;
	@observable diffs = [];

	@action.bound
	fetchVersions(recipeVersionIdOne, recipeVersionIdTwo) {

		this.loading = true;
		return axios.get(`${RECIPE_API}/recipes/ignored/specificversion/${recipeVersionIdOne}`)
			.then((response) => {this.recipeOne = new Recipe(response.data)})
			.then(() => axios.get(`${RECIPE_API}/recipes/ignored/specificversion/${recipeVersionIdTwo}`))
			.then((response) => {this.recipeTwo = new Recipe(response.data)})
			.then(() => this.formatDiff())
			.catch(recipeStore.handleError)
			.finally(() => {
				this.loading = false
			})
	}

	@action.bound
	formatDiff() {

		let arr = [];
		let diff = deepdiff(this.recipeOne, this.recipeTwo, (path, key) => key === "id"); // ignore IDs

		if(diff) {
			diff.forEach(function (el) {
				switch (el.kind) {
					case 'N':
					case 'D':
					case 'E':
						arr.push({
							path: el.path.join('.'),
							rhs: (el.rhs !== null) ? String(el.rhs) : "",
							lhs: (el.lhs !== null) ? String(el.lhs) : ""
						});
						break;

					case 'A':
						arr.push({
							path: el.path.join('.'),
							rhs: (el.rhs !== null) ? String(el.item.rhs) : "",
							lhs: (el.lhs !== null) ? String(el.item.lhs) : "",
							index: el.index
						});
						break;

					default:
						console.error("Unknown kind: " + el.kind);
				}
			});
		}

		this.diffs.replace(arr);
	}

}

const recipeDiffStore = new RecipeDiffStore();
export default recipeDiffStore;