import React, { Fragment } from "react";
import { Button } from "reactstrap";
import { observer, inject } from "mobx-react";
import autobind from "autobind-decorator";
import { withRouter } from "react-router-dom";

@withRouter
@inject("recipeStore")
@inject("snackbarStore")
@inject("enonicStore")
@inject("uiStore")
@observer
class TabButtons extends React.Component {

	constructor(props) {
		super(props);
	}

	@autobind
	handlePreview() {
		this.props.snackbarStore.addSnack("", "Forhåndsvisning ikke implementert.");
	}

	@autobind
	handleSave(publish = false) {
		const { recipeStore } = this.props;
		recipeStore.saveCurrentRecipe(publish)
			.then(() => this.updateLocation());
	}

	@autobind
	handleUnpublish() {
		const { enonicStore } = this.props;
		enonicStore.unpublishRecipe();
	}

	@autobind
	updateLocation() {
		const { location, history, recipeStore } = this.props;
		if(location.pathname.includes("/recipe/new")) {
			history.replace({
				pathname: location.pathname.replace(/(\/recipe\/)new(\/.*$)/, '$1' + recipeStore.currentRecipe.recipeId + '$2')
			});
		}
	}

	render() {
		const { publishedStatus, pathValid } = this.props.enonicStore;
		const { allFieldsValid } = this.props.uiStore;
		const disabled = !(allFieldsValid && pathValid);

		return (
			<div className="tab-buttons">

				<Button color="primary" onClick={this.handlePreview} className="preview">Forhåndsvis</Button>
				{(publishedStatus === "MASTER")
					&&
						<Fragment>
							<Button color="primary" onClick={() => this.handleSave(true)} disabled={disabled} className="publish-button">Lagre og publisér</Button>
							<Button color="secondary" onClick={() => this.handleUnpublish()} disabled={disabled} className="publish-button">Avpublisér</Button>
						</Fragment>
					||
						<Fragment>
							<Button color="primary" onClick={() => this.handleSave(false)} disabled={disabled} className="save-button">Lagre</Button>
							<Button color="primary" onClick={() => this.handleSave(true)} disabled={disabled} className="publish-button">Publisér</Button>
						</Fragment>
				}

			</div>
		);
	}
}

TabButtons.propTypes = {
};

export default TabButtons;