import {action, observable, computed} from "mobx";
import {MENY_API, RECIPE_API} from "../utils/consts";
import {constructImageUrl} from '../utils/mediaUtils';
import axios from "axios";
import moment from 'moment';
import { unionBy } from 'lodash-es';

import recipeStore, {Recipe} from './RecipeStore';
import enonicStore from './EnonicStore';
import snackbarStore from './SnackbarStore';

const options = {
	headers: {
		'ext-rest-api-auth-key': `${MENY_API.apiKey}`,
		'Content-Type': 'application/json;charset=utf-8',
		'Site_key': `${MENY_API.siteKey}`,
		'Authorization': ''
	}
};

class MenyStore {

	@observable loading = false;
	@observable menySite = {name: "Meny"};

	@observable menyRecipeActive = false;
	@observable menyRecipeExists = false; // status call returns 404 if non-existing

	@observable allPublishedRecipes = [];
	@observable publishedRecipe = {}; // the RecipeVersion that has the relationship to the Site node

	@observable bulkList = [];
	@observable bulkListOpText = "";

	@action.bound
	_clearStatus() {
		this.bulkList.forEach(i => {
			i.active = undefined;
			i.status = undefined; // "DELETED", "SAVED", "ERROR"
		});
		return Promise.resolve();
	}

	@action.bound
	_checkStatusItem(item) {
		return axios.get(`${MENY_API.endpoint}/${item.recipeId}/status`, options)
			.then((response) => {
				item.active = response.data.isActive;
				return response;
			})
			.catch((response) => {
				if(response.response.status === 404) {
					item.active = undefined;
				} else {
					console.error(response.response.data);
					item.status = "ERROR";
				}
			});
	}

	@action.bound
	_deleteItem(item) {
		item.status = undefined;
		let resolution = null;
		if(item.active !== undefined && item.active !== true) {  // only delete when Active is truly false
			resolution = axios.delete(`${MENY_API.endpoint}/${item.recipeId}`, options)
				.then(() => axios.delete(`${RECIPE_API}/site/Meny/recipe/${item.recipeId}`))
				.then(() => item.status = "DELETED");
		}
		return Promise.resolve(resolution).catch((response) => {console.error(response.response.data); item.status = "ERROR"});
	}

	@action.bound
	_saveItem(item) {
		item.status = undefined;
		let op = null;
		let opEndpoint = null;
		if(item.active === undefined) {  // POST if new recipe - Active is undefined
			op = axios.post;
			opEndpoint = `${MENY_API.endpoint}`;
		} else if (item.active !== true) { // PUT if recipe is exported but not taken into use - Active is not true
			op = axios.put;
			opEndpoint = `${MENY_API.endpoint}/${item.recipeId}`;
		}

		let resolution = null;
		if(op) {
			resolution = recipeStore.getRecipeOnlyById(item.recipeId)
				.then(() => {
					let menyRecipe = new MenyRecipe(recipeStore.currentRecipe);
					menyRecipe.recipeUrl = item.url;
					this.menySite.published = moment().format('YYYY-MM-DD HH:mm:ss');
					return menyRecipe;
				})
				.then((response) => op(opEndpoint, response, options))
				.then(() => axios.post(`${RECIPE_API}/site/Meny/recipe/${item.recipeId}`, this.menySite))
				.then(() => item.status = "SAVED")
		}

		return Promise.resolve(resolution).catch((response) => {console.error(response.response.data); item.status = "ERROR"});
	}

	_opList(opCallback, opText) { // chain promises synchronously due to global datastructures (ie recipeStore.currentRecipe)
		let index = 0;
		let _this = this;
		this.bulkListOpText = opText;
		function request() {
			let item = _this.bulkList[index];
			let nextResolution = new Promise(function (resolve) {
				index++;
				if (index >= _this.bulkList.length) {
					return Promise.resolve();
				} else {
					return request();
				}
			});
			if(item.recipeId === undefined) {
				item.status = "ERROR";
				return Promise.resolve();
			} else {
				return opCallback(item).then(nextResolution);
			}
		}
		return request().finally(() => {this.bulkListOpText = ""})
	}

	@action.bound
	bulkStatusMenyRecipe(urlList) {
		this.loading = true;

		return enonicStore.resolveRecipeId(urlList)
			.then((response) => {
				let tmpList = unionBy(response.data.urls, urlList.urls, 'url');
				this.bulkList.replace(tmpList);

			})
			.then(() => this._clearStatus())
			.then(() => this._opList(this._checkStatusItem, "Sjekker status"))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false});
	}


	@action.bound
	bulkDeleteMenyRecipe(urlList) {
		this.loading = true;

		return enonicStore.resolveRecipeId(urlList)
			.then((response) => {
				let tmpList = unionBy(response.data.urls, urlList.urls, 'url');
				this.bulkList.replace(tmpList);
			})
			.then(() => this._clearStatus())
			.then(() => this._opList(this._checkStatusItem, "Sjekker status"))
			.then(() => this._opList(this._deleteItem, "Fjerner oppskrifter"))
			.then(() => this._opList(this._checkStatusItem, "Sjekker status"))
			.then(() => this.notify("Oppskrifter fjernet fra Meny: " + this.bulkList.filter(i => i.status === "DELETED").length  + " stk."))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false});
	}

	@action.bound
	bulkSaveMenyRecipe(urlList) {
		this.loading = true;

		return enonicStore.resolveRecipeId(urlList)
			.then((response) => {
				let tmpList = unionBy(response.data.urls, urlList.urls, 'url');
				this.bulkList.replace(tmpList);
			})
			.then(() => this._clearStatus())
			.then(() => this._opList(this._checkStatusItem, "Sjekker status"))
			.then(() => this._opList(this._saveItem, "Lagrer oppskrifter"))
			.then(() => this._opList(this._checkStatusItem, "Sjekker status"))
			.then(() => this.notify("Oppskrifter lagret hos Meny: " + this.bulkList.filter(i => i.status === "SAVED").length  + " stk."))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false});
	}


	@action.bound
	bulkListToString(attribute, value) { // toString of urls with attribute set to value
		return this.bulkList.filter(i => i[attribute] === value).map(i => i.url).join('\n');
	}

	@action.bound
	saveMenyRecipe() {
		let menyRecipe = new MenyRecipe(recipeStore.currentRecipe);

		this.loading = true;
		this.menySite.published = moment().format('YYYY-MM-DD HH:mm:ss');

		if(this.menyRecipeExists) { // published earlier
			return axios.put(`${MENY_API.endpoint}/${recipeStore.currentRecipe.recipeId}`, menyRecipe, options)
				.then(() => axios.post(`${RECIPE_API}/site/Meny/recipe/${recipeStore.currentRecipe.recipeId}`, this.menySite))
				.then(() => this.notify("Oppskrift lagret hos Meny"))
				.then(() => this.getMenyRecipeStatus(recipeStore.currentRecipe.recipeId))
				.catch(recipeStore.handleError)
				.finally(() => {this.loading = false});

		} else { // first time
			return axios.post(`${MENY_API.endpoint}`, menyRecipe, options)
				.then(() => axios.post(`${RECIPE_API}/site/Meny/recipe/${recipeStore.currentRecipe.recipeId}`, this.menySite))
				.then(() => this.notify("Oppskrift oppdatert hos Meny"))
				.then(() => this.getMenyRecipeStatus(recipeStore.currentRecipe.recipeId))
				.catch(recipeStore.handleError)
				.finally(() => {this.loading = false});
		}
	}

	@action.bound
	deleteMenyRecipe(recipeId) {
		this.loading = true;
		return axios.delete(`${MENY_API.endpoint}/${recipeStore.currentRecipe.recipeId}`, options)
			.then(() => this.deleteSiteRelationship())
			.then(() => this.notify("Oppskrift fjernet fra Meny"))
			.then(() => this.getMenyRecipeStatus(recipeStore.currentRecipe.recipeId))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false})
	}

	@action.bound
	deleteSiteRelationship() {
		return axios.delete(`${RECIPE_API}/site/Meny/recipe/${recipeStore.currentRecipe.recipeId}`)
			.then(() => {this.publishedRecipe = {}});
	}


	@action.bound
	getMenyRecipeStatus(recipeId) {
		this.loading = true;
		return axios.get(`${MENY_API.endpoint}/${recipeId}/status`, options)
			.then((response) => this.parseStatusResponse(response))
			.catch((response) => this.catchStatusResponse404(response))
			.then(() => this.fetchPublishedRecipe(recipeId))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false})
	}

	@action.bound
	parseStatusResponse(response) {
		this.menyRecipeActive = response.data.isActive;
		this.menyRecipeExists = true;
		return Promise.resolve();
	}

	@action.bound
	catchStatusResponse404(response) {
		if(response.response.status === 404) {
			this.menyRecipeExists = false;
			this.menyRecipeActive = false;
		} else {
			throw response;
		}
	}

	@action.bound
	fetchPublishedRecipe(recipeId) {
		this.loading = true;
		return axios.get(`${RECIPE_API}/site/Meny/recipe/${recipeId}`)
			.then((response) => this.parseFetchPublishedResponse(response))
			.catch((response) =>  this.catchFetchPublishedResponse404(response))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false});
	}

	@action.bound
	parseFetchPublishedResponse(response) {
		this.publishedRecipe = new Recipe(response.data);
	}

	@action.bound
	catchFetchPublishedResponse404(response) {
		if(response.response.status === 404) {
			this.publishedRecipe = {};
		} else {
			throw response;
		}
	}

	@action.bound
	findAllPublished(){
		this.loading = true;
		return axios.get(`${RECIPE_API}/site/Meny/publishedTo`)
			.then((response) => this.parsePublishedToResponse(response))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false});
	}

	@action.bound
	parsePublishedToResponse(response) {
		this.allPublishedRecipes.replace(response.data);
	}

	@computed
	get currentRecipeEqual() { // whether the current MenyRecipe is equal to the published MenyRecipe
		if(this.publishedRecipe.recipeId && recipeStore.currentRecipe.recipeId) {
			let currentMenyRecipe = new MenyRecipe(recipeStore.currentRecipe);
			let publishedMenyRecipe = new MenyRecipe(this.publishedRecipe);
			return JSON.stringify(currentMenyRecipe) === JSON.stringify(publishedMenyRecipe);
		} else {
			return false;
		}
	}

	@action.bound
	notify(msg) {
		snackbarStore.addSnack("", msg);
		return Promise.resolve();
	}

}

const generateIngredientsString = function(currentRecipe) {
	let lines = currentRecipe.ingredientLines;
	let str = "";

	let getUnit = function(amount, unit) {
		if(!unit) {
			return "";
		}

		let retval;
		if(amount > 1) { // plural
			retval = unit.plural ? unit.plural : unit.abbrev;
		} else { // singular
			retval = unit.singular ? unit.singular : unit.abbrev;
		}
		return " " + retval;
	};

	let getIngredient = function(amount, ingredient) {
		if(!ingredient) {
			return "";
		}

		let retval;
		if(amount >1) { // plural
			retval = ingredient.plural ? ingredient.plural : (ingredient.singular ? ingredient.singular : ingredient.genericName);
		} else { // singular
			retval = ingredient.singular ? ingredient.singular : (ingredient.plural ? ingredient.plural : ingredient.genericName);
		}
		return " " + retval;
	};

	lines.forEach(function (line, index) {
		if(index < lines.length - 1) { // skip last empty ingredient line
			str += (line.amount ? " " + line.amount : "")
				+ getUnit(line.amount, line.unit)
				+ (line.preText ? " " + line.preText : "")
				+ getIngredient(line.amount, line.ingredient)
				+ (line.postText ? " " + line.postText : "")
				+ ((index < lines.length - 2) && ";" || "");
		}
	});

	return str.trim();
};

class MenyRecipe {
	@observable id = ""; // recipeId
	@observable name = ""; // recipe title
	@observable numberOfPersons = 4;
	@observable tip = ""; // semicolon separated list of ingredients
	@observable recipeUrl = ""; // URL of original recipe
	@observable externalImageUrl = ""; // URL to image of recipe

	constructor(currentRecipe) {
		if (typeof currentRecipe === "undefined") {
			return;
		}
		this.id = currentRecipe.recipeId;
		this.name = currentRecipe.displayName;
		this.numberOfPersons = currentRecipe.multipliable ? currentRecipe.portions : 1;
		this.tip = generateIngredientsString(currentRecipe);
		this.recipeUrl = enonicStore.site.urlprefix + enonicStore.enonicRecipe.path;
		this.externalImageUrl = constructImageUrl(currentRecipe.image.url, 1600, 900);
	}

}

const menyStore = new MenyStore();
export default menyStore;