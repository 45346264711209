import React, {Fragment} from "react";
import {Container, Col, Row, InputGroup, InputGroupAddon, Tooltip, Input} from 'reactstrap';
import autobind from 'autobind-decorator';
import TagsSelector from "../editor/TagsSelector";
import {inject, observer} from "mobx-react";
import {convertText} from "../../common/Utils";
import { MdLockOpen, MdLockOutline } from 'react-icons/md';
import RelatedRecipes from "./RelatedRecipes";
import PathDropdown from "./PathDropdown";
import PropTypes from "prop-types";

@inject("recipeStore")
@inject("enonicStore")
@observer
class PathInput extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tooltipOpen: false,
			suggestedDisplayName: ""
		}
	}


	@autobind
	handlePathBlur(e) {
		this.setState({
			tooltipOpen: false
		});
		this.props.onLockClickedCallback();
	}

	@autobind
	handlePathChange(e) {
		const { enonicStore } = this.props;
		enonicStore.urlSlug = e.target.value;
	}


	@autobind
	handlePathFocus(e) {
		const { enonicStore, recipeStore } = this.props;
		const recipe = recipeStore.currentRecipe;
		let suggestedDisplayName = convertText(recipe.displayName);
		if (enonicStore.urlSlug !== suggestedDisplayName && !this.state.pathReadOnly) {
			this.setState({
				tooltipOpen: true,
				suggestedDisplayName: suggestedDisplayName
			});
		}
	}

	render() {
		const { enonicStore, disabled } = this.props;
		const { suggestedDisplayName } = this.state;

		return (
			<Fragment>
				<Input
					tabIndex="-1"
					readOnly={disabled}
					onBlur={this.handlePathBlur}
					onFocus={this.handlePathFocus}
					onChange={this.handlePathChange}
					type="text"
					className="w-80"
					value={enonicStore.urlSlug} id="url"
				/>
				<InputGroupAddon addonType="append">
					{disabled ?
						<MdLockOutline tabIndex="-1" className="clickable lock-icon" onClick={this.props.onLockClickedCallback} />
						:
						<MdLockOpen tabIndex="-1" className="clickable lock-icon" onClick={this.props.onLockClickedCallback} />
					}
				</InputGroupAddon>
				<Tooltip
					placement="top"
					modifiers={{
						preventOverflow: {boundariesElement: 'viewport'},
						offset: {offset: 2},
					}}
					offset="50"
					isOpen={this.state.tooltipOpen}
					autohide={false}
					target="url"
				>
					<span>Forslag til url: <br/><span className="font-weight-bold" >{suggestedDisplayName}</span></span>
				</Tooltip>
			</Fragment>
		);
	}

}

PathInput.propTypes = {
	disabled: PropTypes.bool,
	onLockClickedCallback: PropTypes.func
};


export default PathInput;