// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.play-icon {
    position: absolute;
    display: block;
    top: 25%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    background-clip: padding-box;
    outline: none;
    padding: 32px;
}

.play-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-47%) translateY(-50%);
    color: #000;
}`, "",{"version":3,"sources":["webpack://./assets/media.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,QAAQ;IACR,SAAS;IACT,4CAA4C;IAC5C,UAAU;IACV,kBAAkB;IAClB,0CAA0C;IAC1C,4BAA4B;IAC5B,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,4CAA4C;IAC5C,WAAW;AACf","sourcesContent":[".play-icon {\r\n    position: absolute;\r\n    display: block;\r\n    top: 25%;\r\n    left: 50%;\r\n    transform: translateX(-50%) translateY(-50%);\r\n    z-index: 2;\r\n    border-radius: 50%;\r\n    background-color: rgba(255, 255, 255, 0.6);\r\n    background-clip: padding-box;\r\n    outline: none;\r\n    padding: 32px;\r\n}\r\n\r\n.play-icon i {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translateX(-47%) translateY(-50%);\r\n    color: #000;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
