import { inject, observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import autobind from 'autobind-decorator';
import Spinner from "../../common/Spinner";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	InputGroup,
	InputGroupAddon,
	Label,
	Col
} from 'reactstrap';
import ingredientStore from "../../stores/IngredientStore";
import SyncedInput from "../../common/SyncedInput";
import SearchInput from "../../common/SearchInput";
import {RECIPE_API} from "../../utils/consts";

@inject("snackbarStore")
@inject("ingredientStore")
@observer
class IngredientsModal extends React.Component {

	static apiConfig = {
        endpoint: `${RECIPE_API}/recipes/search`,
        queryParams: {
            searchKey: "terms",
            limit: 30
        }
    };

    @autobind
    handleEmptyValue() {
        this.props.ingredientStore.setRecipe(undefined);
    }

	@autobind
    handleResultSelected(item, _) {
		this.props.ingredientStore.setRecipe(item)
    }

	@autobind
	handleSave() {
		const { snackbarStore, toggle } = this.props;
		const { currentIngredient } = this.props.ingredientStore;

		if(currentIngredient.id) {
			ingredientStore.updateCurrentIngredient(currentIngredient.id)
				.then(snackbarStore.addSnack("Lagret", "Ingrediensen har blitt oppdatert."))
				.then(() => this.setIngredientCallback(currentIngredient))
				.catch(this.commonErrorHandler)
				.finally(toggle());
		} else {
			ingredientStore.createCurrentIngredient()
				.then(snackbarStore.addSnack("Lagret", "Ingrediensen har blitt opprettet."))
				.then(() => ingredientStore.clear())
				.catch(this.commonErrorHandler)
				.finally(toggle());
		}
	}

	@autobind
	setIngredientCallback(ingredient) {
		const {setIngredientCallback} = this.props;
		if(setIngredientCallback) {
			setIngredientCallback(ingredient);
		}
		return Promise.resolve();
	}

	@autobind
	handleCancel(e) {
		this.props.toggle();
		this.props.ingredientStore.clear();
	}

	@autobind
	commonErrorHandler(err) {
		this.props.snackbarStore.addSnack("Feil", "Det oppsto en feil under lagring av ingrediens: " + err);
	}

	@autobind
	handleOnBlur(e) {
		if(e.target.value) {
			this.props.ingredientStore.updateWithNameFromRiversand(e.target.value);
		}
	}

	render() {
		const { toggle, isOpen} = this.props;
		const { currentIngredient, loading } = this.props.ingredientStore;

		const isTINEProduct = !isEmpty(currentIngredient.articleNo);
		const singularLabel = isTINEProduct ? 'Navn' : 'Entall';
		const heading = isTINEProduct ? (
			<ModalHeader>Produkt: {currentIngredient.singular}</ModalHeader>
		) : (
			<ModalHeader>Ingrediens: {currentIngredient.singular} / {currentIngredient.plural}</ModalHeader>
		);

        const recipe = this.props.ingredientStore.getRecipe();
        return (
			<Modal className="ingredient-modal" toggle={toggle} isOpen={isOpen} autoFocus={false} fade={false}>
				{heading}
				<ModalBody>
					<InputGroup>
						<InputGroupAddon className="ingredient-modal-label" addonType="prepend">{singularLabel}</InputGroupAddon>
						<SyncedInput source={currentIngredient} id="singular" />
					</InputGroup>
					{!isTINEProduct &&
					<InputGroup className="mb-1 mt-1">
						<InputGroupAddon className="ingredient-modal-label" addonType="prepend">Flertall</InputGroupAddon>
						<SyncedInput source={currentIngredient} id="plural" />
					</InputGroup>
					}
					<InputGroup className="mb-1 mt-1">
						<InputGroupAddon className="ingredient-modal-label" addonType="prepend">TINE Artikkelnr</InputGroupAddon>
						<SyncedInput source={currentIngredient} id="articleNo" onBlur={this.handleOnBlur}/>
					</InputGroup>
					<InputGroup className="mb-1 mt-1">
						<InputGroupAddon className="ingredient-modal-label"
										 addonType="prepend">Relatert oppskrift</InputGroupAddon>
						<SearchInput field="displayName"
									 id="relatedRecipeSearch"
									 onSelectItem={this.handleResultSelected}
									 handleEmptyValue={this.handleEmptyValue}
									 apiConfig={IngredientsModal.apiConfig}
									 renderSuggestion={this.renderSuggestion}
									 placeholder=""
									 value={recipe ? recipe.displayName : ""}
						/>
					</InputGroup>
					<Col className="ml-2 mt-2">
						<Label>
							<SyncedInput type="checkbox" source={currentIngredient} id="inUse" /> Fortsatt i bruk?
						</Label>
					</Col>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={this.handleSave}>{currentIngredient.id ? 'Oppdater' : 'Lagre'}</Button>
					<Button color="secondary" onClick={this.handleCancel}>Lukk</Button>
				</ModalFooter>
				<Spinner enabled={loading} />
			</Modal>
		);
	}
}

IngredientsModal.propTypes = {
	toggle: PropTypes.func,
	snackbarStore: PropTypes.object,
	ingredientStore: PropTypes.object,
	isOpen: PropTypes.bool,
	setIngredientCallback: PropTypes.func
};

export default IngredientsModal;