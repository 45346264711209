import { observable, action, computed } from 'mobx';
import axios from 'axios';
import { XP_API, XP_API_KEY } from "../utils/consts";
import { set } from 'lodash-es';

import recipeStore from "./RecipeStore";
import snackbarStore from "./SnackbarStore";
import siteStore from "./SiteStore";

class EnonicStore {

	@observable enonicRecipe = new RecipeResponse();
	@observable categories   = [];
	@observable tags = [];
	@observable site = {};
	@observable loading = false;

	@action.bound
	setSite(displayName, force = true) {
		if(this.site.name !== displayName || force) {
			this.loading = true;
			return this.changeSite(displayName)
				.then(() => this.fetchCategories())
				.then(() => this.getRecipe())
				.finally(() => {this.loading = false});
		} else {
			return Promise.resolve();
		}
	}

	@action.bound
	changeSite(displayName) {
		this.site = siteStore.getContributors().find(site => site.name === displayName) || siteStore.getDefaultContributor();
		this.tags = [];
		return Promise.resolve();
	}

	@action
	saveRecipe(currentRecipe, publish = false) {
		let publishReq = new PublishRequest({
			id: currentRecipe.recipeId,
			path: this.enonicRecipe.path,
			title: currentRecipe.displayName,
			description: currentRecipe.description,
			tags: this.tags,
			force: false,
			publish: publish
		});

		let opts = {
			method: "post",
			data: JSON.stringify(publishReq),
			headers: {'Content-Type': 'application/json;charset=utf-8'},
			params: {
				apikey: `${XP_API_KEY}`,
				rootPath: this.site.rootpath
			},
			url: `${XP_API}/_/service/no.tine.recipepublish/publish`
		};

		this.loading = true;
		return axios(opts).then((r) => this.parsePublishResponse(r))
			.finally(() => {this.loading = false});
	}

	@action
	unpublishRecipe() {
		let opts = {
			method: "post",
			headers: {'Content-Type': 'application/json;charset=utf-8'},
			data: JSON.stringify({
				pathOrKey: this.enonicRecipe.contentId
			}),
			params: {
				apikey: `${XP_API_KEY}`,
				rootPath: this.site.rootpath
			},
			url: `${XP_API}/_/service/no.tine.recipepublish/unpublish`
		};
		this.loading = true;
		return axios(opts)
			.then(() => this.enonicRecipe.published = false)
			.finally(() => {this.loading = false})
	}

	@action.bound
	parsePublishResponse(response) {
		let publishResponse = new PublishResponse(response.data);
		if (publishResponse.existingNode) { // TODO: publish was prevented by this node, throw better error
			return Promise.reject(response);
		} else { // refresh
			return this.getRecipe();
		}
	}

	@action
	handlePublishError(error) {
		console.error("Error during publish to XP:", error.response);
	}

	@action
	getRecipe() {
		if(!recipeStore.currentRecipe.recipeId) {
			console.log("Recipe has not been saved yet, no reason to reload");
			return Promise.resolve();
		}

		let opts = {
			params: {
				apikey: `${XP_API_KEY}`,
				rootPath: this.site.rootpath,
				recipeId: recipeStore.currentRecipe.recipeId
			}
		};
		return axios.get(`${XP_API}/_/service/no.tine.recipepublish/recipe`, opts)
			.then((response) => this.parseRecipeResponse(response))
			.catch(this.handleError);
	}

	@action.bound
	handleError(error) {
		console.error("Enonic error", error);
		if (error.response && error.response.status === 408) {
            const title = error.response.status + ": " + error.response.data.error;
            const message = "Recipe publish: " + error.response.data.message;
            snackbarStore.addSnack(title, message, true, 5000);
            return error;
        } else if (error.response && error.response.status !== '404') {
			return error;
		}
	}

	@action.bound
	parseRecipeResponse(response) {
		this.enonicRecipe = new RecipeResponse(response.data);
		this.tags = response.data.tags.map(t => t.name);
		return Promise.resolve();
	}

	@action.bound
	fetchCategories() {
		let opts = {
			params: {
				apikey: `${XP_API_KEY}`,
				rootPath: this.site.rootpath
			}
		};

		return axios.get(`${XP_API}/_/service/no.tine.recipepublish/categories`, opts)
			.then((response) => this.parseCategoryResponse(response))
			.catch(this.handleError);
	}

	@action.bound
	parseCategoryResponse(response) {
		let re = new RegExp("^" + this.site.rootpath, "g");
		let rootNode = {};

		response.data.categories
			.map(c => c.path.replace(re, "")) // remove rootpath
			.sort()                           // sort
			.filter(i => i)                   // filter out empty
			.forEach(c => set(rootNode, c.substring(1).replace(/\//g, "."), undefined)); // populate rootNode with entries

		let result = [];
		let flatten = function recurse (cur, prop) {
			if (Object(cur) !== cur) {
				result.push("/" + prop);
			} else {
				for (var p in cur) {
					if(cur.hasOwnProperty(p)) {
						recurse(cur[p], prop ? prop + "/" + p : p);
					}
				}
			}
		};

		flatten(rootNode, "");
		this.categories.replace(result);
		return Promise.resolve();
	}

	@action.bound
	addTag(tag) {
		// check if already added
		if (!this.tags.find(t => t === tag.displayName)) {
			this.tags.push(tag.displayName);
		}
	}

	@action
	removeTag(tag) {
		this.tags.remove(tag);
	}

	@computed
	get publishedDate() {
		if (this.enonicRecipe.latestVersion) {
			return new Date(this.enonicRecipe[this.enonicRecipe.latestVersion].modifiedTime).toLocaleString();
		} else {
			return null;
		}
	}

	@computed
	get publishedStatus() {
		if (this.enonicRecipe.published) {
			return "MASTER";
		} else {
			if (this.enonicRecipe.latestVersion === "draft") {
				return "DRAFT";
			} else {
				return 'UNPUBLISHED';
			}
		}
	}

	// last part of the url path
	@computed
	get urlSlug() {
		let slug = "";
		if (this.enonicRecipe.path) {
			let i = this.enonicRecipe.path.lastIndexOf('/');
			if (i !== -1) {
				slug = this.enonicRecipe.path.substr(i + 1);
			}
		}
		return slug;
	}

	set urlSlug(slug) {
		let i = this.enonicRecipe.path.lastIndexOf('/');
		if (i !== -1) {
			this.enonicRecipe.path = this.enonicRecipe.path.substr(0, i + 1) + slug;
		}
	}

	@computed
	get pathWithoutSlug() {
		return this.enonicRecipe.path.substring(0, this.enonicRecipe.path.length - this.urlSlug.length);
	}

	@computed
	get pathValid() {
		return this.pathWithoutSlug !== "/" && this.urlSlug !== "";
	}

	@action.bound
	setPath(path){
		this.enonicRecipe.path = path + "/" + this.urlSlug;
	}

	@action.bound
	createEmptyRecipe() {
		this.enonicRecipe = new RecipeResponse();
		this.categories   = [];
		this.tags = [];
		this.site = siteStore.getDefaultContributor();
		if (this.site) {
			this.fetchCategories();
		}
	}

	@action.bound
	resolveRecipeId(bulkList) {
		let opts = {
			method: "post",
			data: JSON.stringify(bulkList),
			headers: {'Content-Type': 'application/json;charset=utf-8'},
			params: {
				apikey: `${XP_API_KEY}`,
				rootPath: this.site.rootpath
			},
			url: `${XP_API}/_/service/no.tine.recipepublish/recipe-url`
		};
		return axios(opts);
	}

}

class PublishResponse {

	/** Whether or not you can replace the existing node */
	@observable canForce = false;

	/** If set, contains information about the node that stops the creation/updating of recipe */
	@observable
	existingNode = {
		_id: "",         /** UUID of the node */
		_name: "",       /** The URL-name of the node */
		_path: "",       /** The relative URL of the node */
		type: "",        /** The Enonic ContentType */
		displayName: ""  /** The human readable name */
	};

	/** If successful in creating/updating recipe, the resulting node is given here */
	@observable
	recipe = {
		_id: "",         /** UUID of the node */
		_name: "",       /** The URL-name of the node */
		_path: "",       /** The relative URL of the node */
		type: "",        /** The Enonic ContentType */
		displayName: ""  /** The human readable name */
	};

	constructor(data) {
		if(typeof data === "undefined") {
			return;
		}

		this.canForce     = data.canForce;
		this.existingNode = data.existingNode;
		this.recipe       = data.recipe;
	}
}

class RecipeResponse {
	/** UUID of the Enonic XP node */
	@observable contentId = "";

	/** The preface of the recipe  */
	@observable description = "";

	/** The relative path of the recipe */
	@observable path = "/";

	/** UUID of the recipe in ODB */
	@observable recipeId = "";

	/** Tags connected to the recipe */
	@observable
	tags = [{
		id: "", /** UUID of the Enonic XP node */
		name: "" /** Human readable name */
	}];

	@observable title; /** Human readable name */

	@observable published; /** Whether or not the recipe is published to master branch */

	@observable latestVersion; /** "master" | "draft" Which branch the recipe is fetched from */

	@observable /** Date information connected to the node in draft branch */
	draft = {
		createdTime: {},
		modifiedTime: {}
	};

	@observable /** Date information connected to the node in master branch */
	master = {
		createdTime: {},
		modifiedTime: {}
	};

	constructor(data) {

		if (typeof data === "undefined") {
			return;
		}

		this.contentId     = data.contentId;
		this.description   = data.description;
		this.path          = data.path;
		this.recipeId      = data.recipeId;
		this.tags          = data.tags;
		this.title         = data.title;
		this.published     = data.published;
		this.latestVersion = data.latestVersion;
		this.draft         = data.draft;
		this.master        = data.master;
	}
}

class CategoryResponse {

	/** ContentKey of the category */
	@observable id = "";

	/** Relative path of the category */
	@observable path = "";

	/** Name of the category */
	@observable displayName = "";

	/** Sub-categories, if any - type: CategoryResponse */
	@observable children = {};

	constructor(data) {
		if (typeof data === "undefined") {
			return;
		}

		this.id          = data.id;
		this.path        = data.path;
		this.displayName = data.displayName;
		this.children    = data.children;
	}

}

class PublishRequest {
	/** The UUID of the recipe from ODB */
	@observable id = "";

	/** The requested path */
	@observable  path = "";

	/** The human readable name of the recipe */
	@observable  title = "";

	/** The preface of the recipe */
	@observable  description = "";

	/** The human readable names of tag connected to the recipe */
	@observable tags = [];

	/** Force creation of recipe on the expense of a shortcut (will not work with any other content) */
	@observable force = false;

	/** Whether or not a recipe should be published at once, or just saved as a draft */
	@observable publish = false;

	constructor(data) {
		if (typeof data === "undefined") {
			return;
		}

		this.id          = data.id;
		this.path        = data.path;
		this.title       = data.title;
		this.description = data.description;
		this.tags        = data.tags;
		this.force       = data.force;
		this.publish     = data.publish;
	}
}

const enonicStore = new EnonicStore();
export default enonicStore;

