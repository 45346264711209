// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-login {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 15px;
}`, "",{"version":3,"sources":["webpack://./assets/login.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,aAAa;AACjB","sourcesContent":[".form-login {\r\n    width: 100%;\r\n    max-width: 400px;\r\n    margin: 0 auto;\r\n    padding: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
