import React from 'react';
import { inject, observer } from 'mobx-react';
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import StepLine from './StepLine';
import autobind from "autobind-decorator";

// Define the properties of the different sortables
const SortableStepLine = SortableElement(observer(({ isLast, step, count }) => (

	<StepLine isLast={isLast} step={step} count={count} />
)));

const SortableSteps = SortableContainer(observer(({ items }) => (
	<div className="step-container">
		{items.map((step, i) => <SortableStepLine key={step.id} count={i+1} index={i} step={step} disabled={i === items.length - 1} isLast={i === items.length - 1} />)}
	</div>
)));

@inject("recipeStore")
@observer
export default class Steps extends React.Component {
	@autobind
	handleNewSortOrder({ oldIndex, newIndex }) {
		if(oldIndex !== newIndex) {
			this.props.recipeStore.currentRecipe.moveStepLine(oldIndex, newIndex);
		}
	}

	render() {
		const steps = this.props.recipeStore.currentRecipe.steps;
		return (<SortableSteps
			lockToContainerEdges={true}
			lockAxis="y"
			useDragHandle={true}
			items={steps}
			onSortEnd={this.handleNewSortOrder}
		/>);
	}
}
