import React from "react";
import { inject, observer } from 'mobx-react';
import PropTypes from "prop-types";
import {Button, ButtonGroup, Col, Form, Row} from "reactstrap";
import Spinner from "../../common/Spinner";
import autobind from "autobind-decorator";
import BulkItem from "./BulkItem";
import {MdRemoveCircleOutline, MdAddCircleOutline, MdErrorOutline} from 'react-icons/md';


@inject("siteStore")
@inject("enonicStore")
@inject("menyStore")
@observer
class BulkPublishMeny extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};''
	}

	componentDidMount() {
		const { siteStore, enonicStore } = this.props;
		siteStore.initContributors().then((data) => {
			enonicStore.setSite();
		});
	}

	@autobind
	handleChange(e) {
		this.setState({value: e.target.value});
	}


	@autobind
	clearResults(e) {
		this.props.menyStore.bulkList.clear();
	}

	@autobind
	handleSubmit(e, action) {
		const {menyStore} = this.props;

		if(!this.state.value.trim()) {
			this.setState({value: "Lim inn liste med url'er her og prøv på nytt..."});
			return;
		}

		let urlList = {
			urls: this.state.value.trim().split('\n').map(u => ( {url: decodeURI(u.trim())} ))
		};

		if(action === "SAVE") {
			menyStore.bulkSaveMenyRecipe(urlList);
		} else if (action === "DELETE") {
			menyStore.bulkDeleteMenyRecipe(urlList);
		} else {
			menyStore.bulkStatusMenyRecipe(urlList);
		}
	}

	@autobind
	copyToClipboard(e, attribute, value) {
		let urlsString = this.props.menyStore.bulkListToString(attribute, value);
		navigator.clipboard.writeText(urlsString);
	}

	render() {
		const { bulkList, bulkListOpText } = this.props.menyStore;
		const menyStoreLoading = this.props.menyStore.loading;

		return (
			<Row>
				<Col sm={9}>
					<div style={{height: "30px"}}>
						<img src={require('../../images/meny-logo-30.png')} />
					</div>
					Eksporter til Meny. Kopier inn en liste med URL til oppskrifter i tekstfeltet neden.
					{bulkList && bulkList.length > 0 &&
						<div>
							<Form>
								{ bulkListOpText && <span>{bulkListOpText}</span> }
								<div className="float-right">
									<Button color="primary" className="mb-2 mt-2" onClick={this.clearResults}>Tilbake</Button>
								</div>
								<div className="form-control" style={{height: "unset", clear: "right", maxHeight: "800px", overflow: "auto"}}>
									{bulkList.map((item, index) => <BulkItem key={item.url} item={item} />) }
								</div>
							</Form>
						</div>
					||

						<div>
							<Form>
								<div className="float-right">
									<Button color="primary" onClick={(e) => this.handleSubmit(e, "STATUS")}>Sjekk status</Button>
									<Button color="primary" className="m-2" onClick={(e) => this.handleSubmit(e, "DELETE")}>Fjern fra Meny</Button>
									<Button color="primary" onClick={(e) => this.handleSubmit(e, "SAVE")}>Eksporter til Meny</Button>
								</div>

								<div className="form-group">
									<textarea className="form-control" value={this.state.value} rows="30" onChange={this.handleChange} />
								</div>
							</Form>
						</div>
					}
				</Col>
				<Col sm={3}>
					Farvekoder og symboler:
					<div className="form-control clickable" style={{background: "white"}} onClick={(e) => this.copyToClipboard(e, "active", undefined)}>Ikke publisert til Meny</div>
					<div className="form-control clickable" style={{background: "lightyellow"}} onClick={(e) => this.copyToClipboard(e, "active", false)}>Under behandling</div>
					<div className="form-control clickable" style={{background: "lightgreen"}} onClick={(e) => this.copyToClipboard(e, "active", true)}>Tatt i bruk av Meny</div>


					<Col sm={12} className="p-0 w-100">
						<ButtonGroup className="w-100">
							<Button className="bulk-button" onClick={(e) => this.copyToClipboard(e, "status", "DELETED")}>
								<MdRemoveCircleOutline className="bulk-item-icon shadow-none" style={{position: "unset"}} />
							</Button>
							<Button className="bulk-button"  onClick={(e) => this.copyToClipboard(e, "status", "SAVED")}>
								<MdAddCircleOutline className="bulk-item-icon shadow-none" style={{position: "unset"}} />
							</Button>
							<Button className="bulk-button" onClick={(e) => this.copyToClipboard(e, "status", "ERROR")}>
								<MdErrorOutline className="bulk-item-icon shadow-none" style={{position: "unset", color: "red"}} />
							</Button>
						</ButtonGroup>
					</Col>
					<span className="small">Klikk på fargekodene eller symbolene over for å kopiere url'ene tilsvarende radene over i utklippstavlen.</span>
				</Col>
				<Spinner enabled={menyStoreLoading} />
			</Row>
		);
	}

}

BulkPublishMeny.propTypes = {
	menyStore: PropTypes.object
};


export default BulkPublishMeny;
