import React from "react";
import { inject, observer } from "mobx-react";
import { NotificationStack } from 'react-notification';
import PropTypes from "prop-types";

@inject("snackbarStore")
@observer
class Snackbar extends React.Component {

	render() {
		const snackbarStore = this.props.snackbarStore;
		return (
			<NotificationStack
				style={{action: {color: "blue"}}}
				notifications={Array.from(snackbarStore.snacks)}
				onDismiss={snack => snackbarStore.delete(snack)}
			/>
		)
	}
}

Snackbar.propTypes = {
	snackbarStore: PropTypes.object
};

export default Snackbar;