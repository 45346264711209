

export const constructImageUrl = function(url, width, height = Math.floor(width / 16 * 9)) {
	if(height !== 0) {
		return url.replace("%width%", width).replace("%height%", height)
	} else {
		return url.replace("%width%", width).replace("h_%height%,", "");
	}
};


export const constructImageUrlWithFocus = function(image, width, height) {

	if(image.sourceWidth === 0 || image.sourceHeight === 0) {
		return constructImageUrl(image.url, width, height);
	}

	let x_offset = Math.floor(image.sourceWidth * image.focusX);
	let y_offset = Math.floor(image.sourceHeight * image.focusY);
	let regex = /c_fill.*\/recipeimage/gi;

	return constructImageUrl(image.url, width, height).replace(regex, "c_fill,x_" + x_offset + ",y_" + y_offset + ",g_xy_center/recipeimage");
};
