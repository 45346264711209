import React from 'react';
import autobind from 'autobind-decorator';
import { observer, inject } from 'mobx-react';
import PropTypes from "prop-types";
import {GOOGLE_API_KEY, GOOGLE_YOUTUBE_API} from "../../utils/consts";
import axios from "axios";
import {Button, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import SyncedInput from "../../common/SyncedInput";
import { MdLocalMovies } from 'react-icons/md';
import FocusLock from 'react-focus-lock';
import Thumbnail from "./Thumbnail";


@inject("snackbarStore")
@observer
class YouTubeVideoSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			videoThumbnail: null,
			showModal: false
		};
		this._source = axios.CancelToken.source();
	}

	componentWillReceiveProps(newProps) {
		if(newProps.video && newProps.video.url) { // fetch
			this.searchYoutube(newProps.video.url);
		} else {
			this.setState({videoThumbnail: null});
		}
	}

	componentWillUnmount() {
		this._source.cancel("Operation canceled due component being unmounted.");
	}

	@autobind
	searchYoutube(youtubeVideoUrl) {

		const { snackbarStore } = this.props;

		let url = new URL(youtubeVideoUrl);
		let youtubeVideoId = url.searchParams.get("v");

		if(!youtubeVideoId) {
			snackbarStore.addSnack("ERROR", "Videolinken er ugyldig - finner ikke id.");
			return;
		}

		const instance = axios.create({cancelToken: this._source.token});
		const backup = instance.defaults.headers.common['Authorization']; // while waiting for https://github.com/axios/axios/pull/1395
		delete instance.defaults.headers.common['Authorization'];
		const params = {
			id: youtubeVideoId,
			key: GOOGLE_API_KEY,
			part: "snippet",
			fields: "items(id,snippet(title,thumbnails))"
		};

		instance.get(`${GOOGLE_YOUTUBE_API}/videos`, { params })
			.then(this.parseYoutubeResult)
			.catch(function(thrown) {
				if (axios.isCancel(thrown)) {
					console.log("Request canceled", thrown.message);
				} else {
					console.error("Error while fetching YouTube video ", thrown.message);
					snackbarStore.addSnack("ERROR", "Problemer med videolink - se konsoll for mer info.");
				}
			})
			.finally(this.mediaLoaded);
		instance.defaults.headers.common['Authorization'] = backup;

	}

	@autobind
	parseYoutubeResult(response) {
		if(response.data && response.data.items[0]) {
			let thumbnails = response.data.items[0].snippet.thumbnails;
			let thumbnail = thumbnails["standard"] ? thumbnails["standard"] : (thumbnails["high"]?thumbnails["high"]: thumbnails["default"]);
			this.setState({videoThumbnail: thumbnail.url});
		}
	}

	@autobind
	handleOnDeleteClick(e) {
		const {onDeleteCallback} = this.props;
		this.setState({videoThumbnail: null});
		onDeleteCallback();
	}

	@autobind
	toggleModal() {
		this.setState({showModal: !this.state.showModal});
	}

	@autobind
	handleOnBlur(e) {
		if(e.target.value) {
			this.searchYoutube(e.target.value);
		}
	}

	render() {
		const { videoThumbnail, showModal } = this.state;
		const { video, showIcon, bigIcon = false} = this.props;

		return (
			<React.Fragment>
				{!showIcon && videoThumbnail &&
					<Thumbnail onDeleteCallback={this.handleOnDeleteClick} onClickCallback={this.toggleModal} thumbnailUrl={videoThumbnail} />
				||
					<MdLocalMovies onClick={this.toggleModal} color={video && video.url ? "#007bff" : "#212529"} className={(bigIcon ? "media-icon" : "media-icon-small") + " clickable"}   />
				}

				<Modal isOpen={showModal} toggle={this.toggleModal} autoFocus={false} className="media-modal" fade={false}>
					<ModalHeader toggle={this.toggleModal}>Rediger YouTube-videolenke</ModalHeader>
					<FocusLock disabled={!showModal}>
						<ModalBody>
							<div className="media-modal-grid">
								<div className="media-modal-grid-title mb-1">
									<InputGroup>
										<InputGroupAddon className="media-modal-input-text" addonType="prepend">Tittel</InputGroupAddon>
										<SyncedInput id="title" source={video} mandatory={true} />
									</InputGroup>
								</div>
								<div className="media-modal-grid-description mb-1">
									<InputGroup>
										<InputGroupAddon className="media-modal-input-text" addonType="prepend">Beskrivelse</InputGroupAddon>
										<SyncedInput id="description" source={video}/>
									</InputGroup>
								</div>
								<div className="media-modal-grid-url">
									<InputGroup>
										<InputGroupAddon className="media-modal-input-text" addonType="prepend">Lenke</InputGroupAddon>
										<SyncedInput id="url" source={video} onBlur={this.handleOnBlur}/>
									</InputGroup>
								</div>
								<div className="media-modal-grid-thumbnail ml-1">
									{videoThumbnail &&
										<Thumbnail onDeleteCallback={this.handleOnDeleteClick} thumbnailUrl={videoThumbnail} />
									||
										<MdLocalMovies className="media-icon"/>
									}
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggleModal}>Lukk</Button>
						</ModalFooter>
					</FocusLock>
				</Modal>

			</React.Fragment>
		);
	}
}

YouTubeVideoSelector.propTypes = {
	video: PropTypes.object,
	onDeleteCallback: PropTypes.func,
};

export default YouTubeVideoSelector;