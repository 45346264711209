import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import { MdDelete } from 'react-icons/md';

@inject("recipeStore")
@observer
class SelectedRelatedRecipes extends React.Component {

	constructor(props) {
		super(props);
	}

	@autobind
	handleClick(recipeId) {
		const { recipeStore } = this.props;
		recipeStore.removeRelatedRecipe(recipeId);
	}


	render() {
		const { recipeStore } = this.props;
		return (
			<Fragment>
				<ul>
					{
						recipeStore.currentRecipe.relatedRecipes
							.map((result) =>
								<li key={result.recipeId} data-recipe-id={result.recipeId}>
									<small className="contrib-label float-right">{result.contributor}</small>
									{result.displayName} <MdDelete className="clickable delete-icon" style={{verticalAlign: 'middle'}} onClick={() => this.handleClick(result.recipeId)}  />
								</li>
							)
					}
				</ul>
			</Fragment>
		);
	}

}

SelectedRelatedRecipes.propTypes = {

};


export default SelectedRelatedRecipes;