import {RECIPE_API, XP_API, XP_API_KEY} from "./consts";

export const enonicSearchApiConfig = {
    endpoint: `${XP_API}/_/service/no.tine.AppBrandTineKjokken/search`,
    queryParams: {
        searchKey: "q",
        apikey: `${XP_API_KEY}`,
        size: 30,
        includeDocuments: true,
    },
    dataNode: "hits.hits"
};

export const recipeSearchApiConfig = {
    endpoint: `${RECIPE_API}/recipes/search`,
    queryParams: {
        searchKey: "terms",
        limit: 30
    }
};