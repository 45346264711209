import {action, observable} from "mobx";
import {Ingredient} from "./IngredientStore";
import axios from "axios";
import {RECIPE_API} from "../utils/consts";
import recipeStore from "./RecipeStore";
import snackbarStore from "./SnackbarStore";

class IngredientSwapStore {

	@observable loading = false;
	@observable ingredientSwap = {
		fromIngredient: {},
		toIngredient: new Ingredient(),
		overrides: new Map()
	};

	@action.bound
	initializeSwap(ingredientId) {
		this.loading = true;

		return axios.get(`${RECIPE_API}/ingredients/${ingredientId}`)
				.then((response) => {this.ingredientSwap.fromIngredient = new Ingredient(response.data)})
				.then(() => axios.get(`${RECIPE_API}/recipes/ingredient/${ingredientId}`))
				.then((response) => this.parseRecipeSummariesResults(response))
				.catch((response) => recipeStore.handleError(response))
				.finally(() => {this.loading = false})
	}

	@action.bound
	parseRecipeSummariesResults(response) {
		let results = new Map();
		let _this = this;
		response.data.forEach(function (recipesummary) {
			results.set(recipesummary, new Ingredient());
		});
		this.ingredientSwap.overrides.replace(results);
		return Promise.resolve();
	}

	@action.bound
	swapIngredient() {
		this.loading = true;
		return axios.post(`${RECIPE_API}/ingredients/swap`, this._preparePostData())
			.then(() => this.notify("Ingrediens byttet."))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false})
	}

	@action.bound
	_preparePostData() {
		let overrides = {};
		[...this.ingredientSwap.overrides].map(entry => {
			if(entry[1].id) {
				let recipeId = entry[0].recipeId;
				let ingredientId = entry[1].id;
				overrides[recipeId] = ingredientId;
			}
		});

		return {
			fromIngredientId: this.ingredientSwap.fromIngredient.id,
			toIngredientId: this.ingredientSwap.toIngredient.id,
			overrides: overrides
		};
	}

	@action.bound
	setDefaultToIngredient(ingredient) {
		this.ingredientSwap.toIngredient = new Ingredient(ingredient);
	}

	@action.bound
	setOverrideToIngredient(recipesummary, ingredient) {
		this.ingredientSwap.overrides.set(recipesummary, new Ingredient(ingredient));
	}

	@action.bound
	notify(msg) {
		snackbarStore.addSnack("OK", msg);
		return Promise.resolve();
	}

}

const ingredientSwapStore = new IngredientSwapStore();
export default ingredientSwapStore;
