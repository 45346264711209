import React from 'react';
import { KeyBindingUtil } from 'draft-js';
import autobind from 'autobind-decorator';
import { inject, observer } from 'mobx-react';
import { SortableHandle } from "react-sortable-hoc";
import {Input, Col, Row, Button} from 'reactstrap';
import { SyncedInput } from '../../common';
import PropTypes from "prop-types";
import MediaSelector from "../selectors/MediaSelector";
import RecipeSelector from "../selectors/RecipeSelector";
import { MdDelete, MdDragHandle } from 'react-icons/md';

const { hasCommandModifier } = KeyBindingUtil;

const SortHandle = SortableHandle(() => (
	<React.Fragment>
		<Button tabIndex="-1" color="link" className="moveHandleSteps" aria-label="Move">
			<MdDragHandle/>
		</Button>
	</React.Fragment>
));

const DeleteHandle = ({ handleRemove }) => (
	<React.Fragment>
		<Button tabIndex="-1" color="link" className="deleteHandleSteps" onClick={handleRemove} aria-label="Close">
			<MdDelete/>
		</Button>
	</React.Fragment>
);

@inject("recipeStore")
@inject("snackbarStore")
@observer
class StepLine extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showHeading: false,
			focusHeading: false
		};
		this.headingInput = React.createRef();
	}

	componentWillMount() {
		const {step} = this.props;
		if(step.heading) {
			this.setState({showHeading: true});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.focusHeading) {
			this.headingInput.current.focus();
			this.setState({focusHeading: false});
		}
	}

	@autobind
	toggleShowHeading() {
		this.setState({
			showHeading: !this.state.showHeading,
			focusHeading: !this.state.focusHeading
		});
	}

	@autobind
	handleChange(e) {
		const { isLast, step, recipeStore } = this.props;
		step.instruction = e.target.value;
		if(isLast && !this.isPlaceholderLine()) {
			recipeStore.addEmptyStep();
		}
	}

	@autobind
	isPlaceholderLine() {
		const { step } = this.props;
		return (step.instruction === "");
	}

	@autobind
	handleKeyPress(e) {
		if (e.keyCode === "H".charCodeAt(0) && hasCommandModifier(e)) { //  Ctrl + H
			this.toggleShowHeading();
			const {isLast, recipeStore} = this.props;
			if (isLast) {
				recipeStore.addEmptyStep();
			}
			e.preventDefault();
		}
	}

	@autobind
	deleteVideo(e) {
		const { step } = this.props;
		step.video = {mediaType: "VIDEO"};
	}

	@autobind
	deleteImage(e) {
		const { step } = this.props;
		step.image = {mediaType: "IMAGE"};
	}

	@autobind
	deleteRecipe(e) {
		const { step } = this.props;
		step.recipe = {};
	}

	@autobind
	deleteStep() {
		const id = this.props.step.id;
		this.props.recipeStore.removeStep(id);
	}

	render() {
		const { step, isLast, count } = this.props;
		const { showHeading } = this.state;

		return (
			<div className="stepline-container mb-5">
				<Row className="no-gutters">
					<Col className="stepline-header">
						{!isLast && <SortHandle />}
						<span className="ml-2 font-weight-bold">{isLast?"Nytt steg" : "Steg " + count}</span>
						{!isLast && <DeleteHandle handleRemove={this.deleteStep}/>}
					</Col>
				</Row>

				<div className="grid-container">
					<div className="grid-item-instruction">
						<SyncedInput style={{display: showHeading ? "initial" : "none"}} className="bold-field" id="heading" placeholder="Overskrift" innerRef={this.headingInput} onKeyDown={this.handleKeyPress} source={step} />
						<Input type="textarea" rows={4} value={step.instruction} onKeyDown={this.handleKeyPress} onChange={this.handleChange}  />
					</div>
					<div className="grid-item-tip">
						<SyncedInput id="tip" rows={2} rowsExpand={6} rich={true} placeholder="Tips til dette steget" source={step} />
					</div>
					<div className="grid-item-image">
						<MediaSelector media={step.image} showIcon={true} onDeleteCallback={this.deleteImage} />
					</div>
					<div className="grid-item-video">
						<MediaSelector media={step.video} showIcon={true} onDeleteCallback={this.deleteVideo} />
					</div>
					<div className="grid-item-recipe">
						<RecipeSelector recipe={step.recipe} showIcon={true} onDeleteCallback={this.deleteRecipe}/>
					</div>
				</div>

			</div>
		);
	}
}

StepLine.propTypes = {
	isLast: PropTypes.bool.isRequired,
	step: PropTypes.object,
	count: PropTypes.number
};

export default StepLine;
