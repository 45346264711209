import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import autobind from "autobind-decorator";
import FocusLock from "react-focus-lock";

@inject("recipeStore")
@inject("menyStore")
@observer
class PublishMeny extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			showConfig: false
		};
	}

	@autobind
	handleSave() {
		const { menyStore } = this.props;
		menyStore.saveMenyRecipe();
		this.toggleConfirm();
	}

	@autobind
	handleDelete() {
		const { menyStore } = this.props;
		menyStore.deleteMenyRecipe();
	}

	@autobind
	toggleModal() {

		if(!this.state.showModal) {
			this.props.menyStore.findAllPublished();
		}

		this.setState({showModal: !this.state.showModal});
	}

	@autobind
	toggleConfirm() {
		this.setState({showConfirm: !this.state.showConfirm});
	}

	render() {

		const { showModal, showConfirm } = this.state;
		const { menyStore, recipeStore } = this.props;
		const menySite = menyStore.menySite;

		const buttonDisabled = !recipeStore.currentRecipe.recipeId;

		const activeStatus = menyStore.menyRecipeExists
			? (menyStore.menyRecipeActive
				? "Klar til bruk"
				: "Under behandling")
			: "Ikke publisert til Meny";

		const currentPublishStatus =  menyStore.menyRecipeExists
			? (menyStore.currentRecipeEqual
				? "Ingen endringer oppdaget - oppskriftene er like."
				: "Endringer oppdaget!")
			: "Ikke publisert til Meny";

		return (

			<div className="p-3">
				<br />
				<div>Status: {currentPublishStatus}</div>
				<div>Status hos Meny: {activeStatus}</div>
				<Button color="primary" onClick={() => this.toggleConfirm()} className="ml-0 mr-2">Send til Meny</Button>
				<Button color="primary" disabled={buttonDisabled} onClick={() => this.handleDelete()} className="m-2">Fjern</Button>
				<Button color="primary" onClick={() => this.toggleModal()} className="m-2">Vis alle publiserte oppskrifter</Button>

				<Modal isOpen={showModal} toggle={this.toggleModal} autoFocus={false} fade={false}>
					<ModalHeader toggle={this.toggleModal}>Oppskrifter hos Meny</ModalHeader>
					<FocusLock disabled={!showModal}>
						<ModalBody>
							<ul>
								{
									menyStore.allPublishedRecipes
										.map((result, index) =>
											<li key={result.recipeId} data-recipe-id={result.recipeId}>
												<a className='external-link' target="_blank" href={`/recipe/${result.recipeId}`}>{result.displayName}</a>
											</li>
										)
								}
							</ul>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggleModal}>Lukk</Button>
						</ModalFooter>
					</FocusLock>
				</Modal>

				<Modal isOpen={showConfirm} toggle={this.toggleConfirm} autoFocus={false} fade={false}>
					<ModalHeader toggle={this.toggleConfirm}>Oppskrifter hos Meny</ModalHeader>
					<FocusLock disabled={!showConfirm}>
						<ModalBody>
							Er du sikker på at du ønsker å sende denne oppskriften til Meny?
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggleConfirm}>Lukk</Button>
							<Button color="primary" disabled={buttonDisabled} onClick={() => this.handleSave()} className="ml-2">{menyStore.menyRecipeExists ? "Oppdater" : "Lagre"}</Button>
						</ModalFooter>
					</FocusLock>
				</Modal>

			</div>
		);
	}

}

PublishMeny.propTypes = {

};


export default PublishMeny;