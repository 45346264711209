import {action, observable} from "mobx";
import {Ingredient} from "./IngredientStore";
import axios from "axios";
import {RECIPE_API} from "../utils/consts";
import {RecipeSummary} from "./RecipeStore";
import recipeStore from "./RecipeStore";

class IngredientSearchStore {
	@observable searchResults = [];
	@observable recipeResults = [];
	@observable loading = false;
	tokenSource = axios.CancelToken.source();

	@action
	getAllIngredients(filterCallback) {
		this.loading = true;
		return axios.get(`${RECIPE_API}/ingredients/all`)
			.then((response) => this.parseSearchResults(response, filterCallback))
			.finally(() => {this.loading = false});
	}

	@action
	search(terms) {
		if(terms.length > 1) {
			this.loading = true;
			this.searchResults.clear();
			this.tokenSource.cancel("New request supercedes previous request");
			this.tokenSource = axios.CancelToken.source();

			return axios.get(`${RECIPE_API}/ingredients/search`, {cancelToken: this.tokenSource.token, params: {terms}})
				.then((response) => this.parseSearchResults(response))
				.catch(function (thrown) {
					if (axios.isCancel(thrown)) {
						console.log("Request canceled:", thrown.message);
					} else {
						return Promise.reject(thrown);
					}
				})
				.catch((response) => recipeStore.handleError(response))
				.finally(() => {
					this.loading = false
				});
		}
	}

	@action.bound
	parseSearchResults(response, filterCallback) {
		let results = [];
		response.data.forEach(function (ingredient) {
			if(typeof filterCallback === 'function') {
				// If filterCallback is function and returns false, skip this ingredient
				if(!filterCallback(ingredient)) {
					return;
				}
			}
			results.push(new Ingredient(ingredient));
		});

		results.sort((a,b) => a.singular.localeCompare(b.singular));
		this.searchResults.replace(results);
		return Promise.resolve();
	}

	@action.bound
	replaceIngredient(ingredient) {
		let index = this.searchResults.findIndex(i => i.id === ingredient.id);
		if(index >= 0) {
			this.searchResults[index] = ingredient;
		}
	}

	@action
	getRecipesWithIngredientId(ingredientId) {
		this.loading = true;
		this.recipeResults.clear();
		return axios.get(`${RECIPE_API}/recipes/ingredient/${ingredientId}`)
			.then((response) => this.parseRecipeSummariesResults(response))
			.catch((response) => recipeStore.handleError(response))
			.finally(() => {this.loading = false})
	}

	@action.bound
	parseRecipeSummariesResults(response) {
		let results = [];
		response.data.forEach(function (recipesummary) {
			results.push(new RecipeSummary(recipesummary));
		});
		results.sort((a,b) => a.displayName.localeCompare(b.displayName));
		this.recipeResults.replace(results);
		return Promise.resolve();
	}

}

const ingredientSearchStore = new IngredientSearchStore();
export default ingredientSearchStore;