import React from 'react';
import { SearchInput } from '.';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { RECIPE_API } from "../utils/consts";
import classnames from 'classnames';
import {get} from "lodash-es";


@inject("recipeStore")
@inject("uiStore")
@inject("ingredientStore")
@observer
class IngredientSearchInput extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			onlyShowInUse: true
		};
	}

	@autobind
	handleKeyDown(e) {
		if(e.key === "Shift" && this.state.onlyShowInUse) {
			this.setState({
				onlyShowInUse: false
			});
		}
	}

	@autobind
	handleKeyUp(e) {
		if(e.key === "Shift" && !this.state.onlyShowInUse) {
			this.setState({
				onlyShowInUse: true
			});
		}
	}

	@autobind
	filterVisibleIngredients(ingredient) {
		if(this.state.onlyShowInUse) {
			return get(ingredient, 'inUse', true);
		}
		return true;
	}

	@autobind
	colorizeNotInUse(ingredient) {
		if(get(ingredient, 'inUse', true) === false) {
			return "text-danger";
		}
		return "";
	}

	render() {
		const { ingredient, recipeStore, uiStore, ingredientStore, clearInputOnSelect, filterCallback, ...restProps  } = this.props;

		const ingredientClassNames = classnames({
			"w-100": true,
			"text-danger": (!(ingredient || {}).inUse)
		});

		const ingredientApiConfig = {
			endpoint: `${RECIPE_API}` + "/ingredients/search",
			queryParams: {
				searchKey: "terms"
			}
		};

		const ingredientFilter = (ingredient) => this.filterVisibleIngredients(ingredient) && (filterCallback === undefined || filterCallback(ingredient));

		return (
			<SearchInput
				{...restProps}
				field="singular"
				source={ingredient}
				className={ingredientClassNames}
				apiConfig={ingredientApiConfig}
				onKeyDown={this.handleKeyDown}
				onKeyUp={this.handleKeyUp}
				filterCallback={ingredientFilter}
				colorizeCallback={this.colorizeNotInUse}
				clearInputOnSelect={clearInputOnSelect}
			/>
		);
	}
}

IngredientSearchInput.propTypes = {
	...SearchInput.propTypes,
	ingredient: PropTypes.object
};

export default IngredientSearchInput;
