import React from 'react';
import {inject, observer} from 'mobx-react';

@inject("recipeStore")
@observer
class IngredientsHeader extends React.Component {
	render() {
		return (
			<div className="ingredientheader-container row">
				<div className="w-10 small">Mengde</div>
				<div className="w-10 small">Enhet</div>
				<div className="w-15 small">Før ingr.</div>
				<div className="w-30 small">Ingrediens</div>
				<div className="w-20 small">Alternative ingredienser</div>
				<div className="w-10 small">Etter ingr.</div>
                <div className="w-5 small">Kan sløyfes</div>
			</div>
		);
	}
}

export default IngredientsHeader;