import React  from 'react';
import { Nav, Navbar, NavbarBrand, NavLink, NavItem, Collapse, NavbarToggler } from 'reactstrap';
import {Link, Route, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import VersionsModal from "./pages/editor/VersionsModal";
import {CONFIRM_ON_NAVIGATE} from "./utils/consts";

@withRouter
@inject("recipeStore")
@inject("authStore")
@observer
class NavigationBar extends  React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			showVersionsModal: false
		};
	}

	@autobind
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	@autobind
	toggleVersionsModal() {
		this.setState({
			showVersionsModal: !this.state.showVersionsModal
		});
	}

	@autobind
	createEmptyRecipe() {
		const { recipeStore, history, } = this.props;

		if (this.acceptingNavigation(recipeStore)) {
			recipeStore.markClean();
			recipeStore.createEmptyRecipe();
			history.push('/recipe/new', null);
		}
	}

	@autobind
	navigateAway(e) {
		const { recipeStore } = this.props;
		if (this.acceptingNavigation(recipeStore)) {
			recipeStore.markClean();
		} else {
			e.preventDefault();
		}
	}

	@autobind
	acceptingNavigation(recipeStore) {
		if (recipeStore.recipeIsDirty && CONFIRM_ON_NAVIGATE) {
			return confirm("Vil du forlate siden?\nDet kan hende endringene dine ikke er lagret.");
		} else {
			return true;
		}
	}

	@autobind
	showVersionsModal() {
		const {recipeStore} = this.props;
		const {currentRecipe} = recipeStore;
		recipeStore.getAllVersionsById(currentRecipe.recipeId)
			.then(() => {this.setState({showVersionsModal: true})});
	}

	@autobind
	setVersionCallback(recipeVersionId) {
		const {recipeStore} = this.props;
		recipeStore.getRecipeByRecipeVersionId(recipeVersionId); // TODO: what about published status to Meny? Can't rollback - need to include them
	}

	render() {
		const { authStore, recipeStore } = this.props;
		const versionLinkDisabled = (recipeStore.currentRecipe.recipeId === "");

		return(
			<Navbar color={"dark"} dark expand="md">
				<NavbarBrand href="/">TOR 2.1 (xp7)</NavbarBrand>
				<NavbarToggler onClick={this.toggle} />
				{authStore.loggedIn &&
					<Collapse isOpen={this.state.isOpen} navbar>
						<Nav className="ml-auto" navbar>
							<Route path="/recipe/:id" render={() => (
								<NavItem>
									<NavLink disabled={versionLinkDisabled} onClick={this.showVersionsModal}>Versjoner</NavLink>
									<VersionsModal isOpen={this.state.showVersionsModal} toggle={this.toggleVersionsModal} setVersionCallback={this.setVersionCallback}/>
								</NavItem>
							)} />
							<NavItem>
								<NavLink onClick={this.navigateAway} tag={Link} to="/ingredients">Ingredienser</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={this.createEmptyRecipe}>Opprett ny oppskrift</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={this.navigateAway} tag={Link} to="/">Tilbake til søk</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				}
			</Navbar>
		);
	}
}

export default NavigationBar;