import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import axios from 'axios';
import {CONFIRM_ON_NAVIGATE, RECIPE_API} from "./utils/consts";

// Only import dev tools if we are outside production build, else just use Fragment
const DevTools = (process.env.NODE_ENV !== 'production') ? require('mobx-react-devtools').default : Fragment;

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';

import authStore from './stores/AuthenticationStore';
import recipeStore from './stores/RecipeStore';
import siteStore from './stores/SiteStore';
import searchStore from './stores/SearchStore';
import snackbarStore from './stores/SnackbarStore'
import enonicStore from './stores/EnonicStore'
import ingredientStore from './stores/IngredientStore';
import ingredientSearchStore from './stores/IngredientSearchStore';
import ingredientSwapStore from './stores/IngredientSwapStore';
import uiStore from "./stores/UIStore";
import menyStore from "./stores/MenyStore";
import recipeDiffStore from './stores/RecipeDiffStore';

axios.interceptors.response.use(r => r, function (error) {
	if(error.response && error.response.config.url.includes(RECIPE_API) && error.response.status === 401) {
		authStore.removeToken();
	}
	return Promise.reject(error);
});

import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import '../assets/main.css';

const eventFunction = window.attachEvent || window.addEventListener;
const eventName = window.attachEvent ? 'onbeforeunload' : 'beforeunload';

// display confirmation dialog on navigation if recipe is altered
eventFunction(eventName, function(e) {
	if(recipeStore.recipeIsDirty && CONFIRM_ON_NAVIGATE) {
		e.preventDefault();
		e.returnValue = null;
		return null;
	}
});

let preventingLeave = false;

if (window.history) {
	addEventListener('popstate', function() {
		if(recipeStore.recipeIsDirty && CONFIRM_ON_NAVIGATE) {
			let leavePage;
			if (preventingLeave) {
				leavePage = false;
			} else {
				leavePage = confirm("Vil du forlate siden?\nDet kan hende endringene dine ikke er lagret.");
			}

			if (leavePage) {
				recipeStore.markClean();
			} else if (!preventingLeave) {
				preventingLeave = true;
				window.history.forward()
			} else {
				preventingLeave = false;
			}
		}
	});
}

const stores = {
	authStore,
	recipeStore,
	siteStore,
	searchStore,
	snackbarStore,
	enonicStore,
	ingredientStore,
	ingredientSearchStore,
	ingredientSwapStore,
	uiStore,
	menyStore,
	recipeDiffStore
};

ReactDOM.render((
	<Fragment>
		<Provider {...stores}>
			<App />
		</Provider>
		<DevTools />
	</Fragment>
), document.getElementById('root'));

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;

		ReactDOM.render(
			<Fragment>
				<Provider {...stores}>
					<NextApp />
				</Provider>
				<DevTools />
			</Fragment>,
			document.getElementById('root')
		);
	})
}