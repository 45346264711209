import {observable, action, computed, reaction, toJS} from 'mobx';
import shortid from 'shortid';
import axios from 'axios';
import removeElement from 'lodash-es/remove';
import { isEmpty } from 'lodash-es';
import {RECIPE_API} from "../utils/consts";
import { arrayMove } from 'react-sortable-hoc';
import enonicStore from './EnonicStore';
import snackbarStore from './SnackbarStore';
import menyStore from './MenyStore';
import {AlternativeIngredient} from "./IngredientStore";

class RecipeStore {
	@observable currentRecipe = new Recipe();
	@observable allVersions = [];
	@observable loading = false;

	recipeIsDirty = false;

	@action
	getRecipeById(recipeId) {
		this.loading = true;
		return axios.get(`${RECIPE_API}/recipes/${recipeId}`)
			.then((response) => this.parseCurrentRecipe(response))
			.then(() => enonicStore.setSite(this.currentRecipe.contributor.name, true))
			.then(() => menyStore.getMenyRecipeStatus(recipeId))
			.then(() => this.markDirty())
			.catch(this.handleError)
			.finally(() => {this.loading = false})
	}

	@action
	getRecipeOnlyById(recipeId) {
		return axios.get(`${RECIPE_API}/recipes/${recipeId}`)
			.then((response) => this.parseCurrentRecipe(response))
			.catch(this.handleError);
	}

	@action
	getRecipeByRecipeVersionId(recipeVersionId) {
		this.loading = true;
		return axios.get(`${RECIPE_API}/recipes/ignored/specificversion/${recipeVersionId}`)
			.then((response) => this.parseCurrentRecipe(response))
			.then(() => enonicStore.setSite(this.currentRecipe.contributor.name, true))
			.then(() => this.markDirty())
			.catch(this.handleError)
			.finally(() => {this.loading = false})
	}

	@action.bound
	parseCurrentRecipe(response) {
		this.currentRecipe = new Recipe(response.data);
		return Promise.resolve();
	}

	@action
	addEmptyStep() {
		this.currentRecipe.steps.push(new Step());
	}

	@action
	addEmptyIngredientLine() {
		this.currentRecipe.ingredientLines.push(new IngredientLine());
	}

	@action
	removeIngredientLine(id) {
		removeElement(this.currentRecipe.ingredientLines, i => (i.id === id));
	}

	@action
	removeStep(id) {
		removeElement(this.currentRecipe.steps, step => (step.id === id));
	}

	@action
	addRelatedRecipe(relatedRecipe) {
		this.currentRecipe.relatedRecipes.push(relatedRecipe);
	}

	@action
	removeRelatedRecipe(recipeId) {
		removeElement(this.currentRecipe.relatedRecipes, relatedRecipe => (relatedRecipe.recipeId === recipeId));
	}

    @action
    addRelatedXpArticle(relatedXPArticle) {
        this.currentRecipe.relatedXpArticles.push(relatedXPArticle);
    }

    @action
    removeRelatedXpArticle(id) {
        removeElement(this.currentRecipe.relatedXpArticles, article => article.id === id);
    }

	@action
	removeImage() {
		this.currentRecipe.image = new Media("IMAGE");
	}

	@action
	removeVideo() {
		this.currentRecipe.video = new Media("VIDEO");
	}

	@action
	saveCurrentRecipe(publish = false) {
		this.loading = true;
		if(this.currentRecipe.recipeId === "") { // newly created recipe
			return axios.post(`${RECIPE_API}/recipes`, this.currentRecipe)
				.then(response => this.currentRecipe.recipeId = response.data.id)
				.then(() => enonicStore.saveRecipe(this.currentRecipe, publish))
				.then(() => axios.post(`${RECIPE_API}/recipes/commit/${this.currentRecipe.recipeId}`))
				.then(() => this.handleSaveRecipe())
				.then(() => this.markClean())
				.catch(this.handleError)
				.finally(() => {this.loading = false});
		} else {
			return axios.put(`${RECIPE_API}/recipes/${this.currentRecipe.recipeId}`, this.currentRecipe)
				.then(() => enonicStore.saveRecipe(this.currentRecipe, publish))
				.then(() => axios.post(`${RECIPE_API}/recipes/commit/${this.currentRecipe.recipeId}`))
				.then(() => this.handleSaveRecipe())
				.then(() => this.markClean())
			    .catch(this.handleError)
				.finally(() => {this.loading = false});
		}
	}

	@action
	getAllVersionsById(recipeId) {
		this.loading = true;
		return axios.get(`${RECIPE_API}/recipes/${recipeId}/versions`)
			.then((response) => this.parseAllVersions(response))
			.catch(this.handleError)
			.finally(() => {this.loading = false})
	}

	@action.bound
	parseAllVersions(response) {
		this.allVersions = response.data;
		return Promise.resolve();
	}

	@action
	handleSaveRecipe() {
		snackbarStore.addSnack("", "Oppskrift lagret.");
		return Promise.resolve();
	}

	@action
	handleError(error) {

		if(error.response) {
			console.error(JSON.stringify(error.response || error, null, 2));
		} else {
			console.trace(error);
		}

		let code;
		let msg;
		if(error.response && error.response.data) {
		 	code = error.response.status + ": " + error.response.statusText;
		 	msg = error.response.data.error || error.response.data.errors || error.response.data.message || "Ukjent feil - se konsoll for feilmelding";
		 } else {
		 	code = "ERROR";
		 	msg = error.response || "Ukjent feil - se konsoll for feilmelding";
		}
		snackbarStore.addSnack(code, msg, true, 5000);
	}

	@action.bound
	createEmptyRecipe() {
		this.currentRecipe = new Recipe();
		enonicStore.createEmptyRecipe();
	}

	markClean() {
		this.recipeIsDirty = false;
	}

	markDirty() {
		this.recipeIsDirty = false;
		reaction(() => toJS(this.currentRecipe),
			(newVal, reaction) => {
				this.recipeIsDirty = true;
				reaction.dispose();
			},
		);
	}

}

export class Recipe {
	@observable name = "";
	@observable recipeId = "";
	@observable displayName = "";
	@observable shortName = "";
	@observable shortNamePlaceholder = "";
	@observable description = "";
	@observable metaDescription = "";
	@observable metaDescriptionPlaceholder = "";
	@observable image = new Media("IMAGE");
	@observable video = new Media("VIDEO");
	@observable workTime = "";
	@observable cookingTime = "";
	@observable contentKey = "";
	@observable portions = 4;
	@observable portionLabel = "";
	@observable utensils = "";
	@observable multipliable = true;
	@observable difficulty = "easy";
	@observable steps = [ new Step() ];
	@observable ingredientLines = [ new IngredientLine() ];
	@observable tip = "";
	@observable relatedRecipes = [];
    @observable relatedXpArticles = [];
	@observable contributor = new Contributor();
	@observable publishedSites = [];

	constructor(response) {
		if(typeof response === "undefined") {
			return;
		}

		const { stepGroups = [], ingredientGroups = [], ...rest } = response;
		this.name = rest.name;
		this.recipeId = rest.recipeId;
		this.displayName = rest.displayName;
		this.shortName = rest.shortNameCopied ? null : rest.shortName;
		this.shortNamePlaceholder = rest.shortNameCopied ? rest.shortName : rest.displayName;
		this.description = rest.description;
		this.metaDescription = rest.metaDescriptionCopied ? null : rest.metaDescription;
		this.metaDescriptionPlaceholder = rest.metaDescriptionCopied ? rest.metaDescription : rest.description;
		this.image = rest.image && new Media(rest.image) || new Media("IMAGE");
		this.video = rest.video && new Media(rest.video) || new Media("VIDEO");
		this.cookingTime = rest.cookingTime;
		this.workTime = rest.workTime;
		this.utensils = rest.utensils;
		this.portionLabel = rest.portionLabel;
		this.multipliable = rest.multipliable;
		this.difficulty = rest.difficulty;
		this.tip = rest.tip;
		this.relatedRecipes = rest.relatedRecipes.map(r => new RecipeSummary(r));
		this.relatedXpArticles = rest.relatedXpArticles.map(r => new XpArticle(r));
		this.portions = rest.portions;
		this.portionLabel = rest.portionLabel;
		this.contributor = rest.contributor || new Contributor();

		if(this.displayName && this.image && !this.image.title) {
			this.image.title = this.displayName;
		}

		const allLines = [];
		ingredientGroups && ingredientGroups.forEach(function (group) {
			if(group.name && group.name.length > 0 && group.ingredientLines.length > 0) {
				group.ingredientLines[0].heading = group.name;
			}
			allLines.splice(allLines.length, 0, ...group.ingredientLines);
		});
		this.ingredientLines = allLines.map(il => new IngredientLine(il));
		this.ingredientLines.push(new IngredientLine());

		const allSteps = [];
		stepGroups && stepGroups.forEach(function(s){
			if(s.name && s.name.length > 0 && s.steps.length > 0) {
				s.steps[0].heading = s.name;
			}
			allSteps.splice(allSteps.length, 0, ...s.steps);
		});
		this.steps = allSteps.map(s => new Step(s));
		this.steps.push(new Step());

		this.publishedSites = rest.publishedSites;
	}

	@action.bound
	moveIngredientLine(oldIndex, newIndex) {
		this.ingredientLines.replace(arrayMove(this.ingredientLines, oldIndex, newIndex));
	}

	@action.bound
	moveStepLine(oldIndex, newIndex) {
		this.steps.replace(arrayMove(this.steps, oldIndex, newIndex));
	}

	groupIngredientLines() {
		return this.ingredientLines
			.filter((v, i) => i !== this.ingredientLines.length - 1)            // Remove last line (empty placeholder)
			.reduce((resultLines, line) => {                                    // Start new group for each line
				if(line.heading || resultLines.length === 0) {                  // with a heading defined
					resultLines.push({
						name: line.heading,
						ingredientLines: [ line ]
					});
				} else {
					resultLines[resultLines.length - 1].ingredientLines.push(line);
				}

				return resultLines;
			}, []);
	}

	groupSteps() {
		return this.steps
			.filter((v, i) => i !== this.steps.length - 1)        // Remove last line (empty placeholder)
			.reduce((resultSteps, step) => {
				if(step.heading || resultSteps.length === 0) {
					resultSteps.push({
						name: step.heading,
						steps: [ step ]
					});
				} else {
					resultSteps[resultSteps.length - 1].steps.push(step);
				}
				return resultSteps;
			}, []);
	}

	toJSON() {
		// Remove steps and ingredientLines, these need further processing as stepGroup and ingredientGroups
		const { steps, ingredientLines, shortNamePlaceholder, metaDescriptionPlaceholder, utensils, ...rest } = this;
		return {
			...rest,
			stepGroups: this.groupSteps(),
			ingredientGroups: this.groupIngredientLines(),
			utensils: utensils || null
		}
	};

	generateJson() {
		return JSON.stringify(this, null, 4);
	}
}

class Media {
	@observable title = "";
	@observable description = "";
	@observable contentKey = "";
	@observable url = "";
	@observable targetUrl = "";
	@observable attribution = "";
	@observable focusX = 0.5;
	@observable focusY = 0.5;
	@observable sourceWidth = 0;
	@observable sourceHeight = 0;
	@observable mediaType = "";

	constructor(data) {
		if(typeof data === "undefined") {
			return;
		}

		if(typeof data === 'string') {
			this.mediaType = data;
			return;
		}

		this.title = data.title;
		this.description = data.description;
		this.contentKey = data.contentKey;
		this.url = data.url;
		this.targetUrl = data.targetUrl;
		this.focusX = data.focusX;
		this.focusY = data.focusY;
		this.attribution = data.attribution;
		this.sourceHeight = data.sourceHeight;
		this.sourceWidth = data.sourceWidth;
		if(data.url &&  (data.url.includes("recipeimage"))) {
			this.mediaType = "IMAGE";
		} else {
			this.mediaType = "VIDEO";
		}
	}

	toJSON() {
		if(this.url && this.url !== "") {
			if(this.mediaType === "IMAGE") {
				let regex = /c_fill,.*\/recipeimage/gi;
				const {url, ...rest} = toJS(this);
				return {
					...rest,
					url: url.replace(regex, "c_fill/recipeimage") // strip extra scale & crop options
				};
			} else {
				return this;
			}
		} else {
			return null;
		}
	}

 }

class Step {
	id = null;
	@observable heading = null;
	@observable instruction = "";
	@observable tip = "";
	@observable image = new Media("IMAGE");
	@observable video = new Media("VIDEO");
	@observable recipe = new RecipeSummary();

	constructor(obj) {
		this.id = shortid.generate();
		if(typeof obj === 'undefined') {
			return;
		}

		if(typeof obj === 'string') {
			obj = { description: obj };
		}
		this.heading = obj.heading || null;
		this.instruction = obj.instruction;
		this.tip = obj.tip;
		this.image = obj.image || new Media("IMAGE");
		this.video = obj.video || new Media("VIDEO");
		this.recipe = new RecipeSummary(obj.recipe);
	}

	toJSON() {
		return {
			instruction: this.instruction,
			tip: this.tip,
			image: this.image.url ? this.image : null,
			video: this.video.url ? this.video : null,
			recipe: this.recipe.recipeId ? this.recipe : null
		};
	}
}

export class RecipeSummary {
	@observable recipeId = null;
	@observable displayName = null;
	@observable description = null;
	@observable imageUrl = null;
	@observable workTime = null;
	@observable workTimeRange = null;
	@observable cookingTime = null;
	@observable cookingTimeRange = null;
	@observable difficulty = null;
	@observable contributor = null;

	constructor(obj) {
		if(typeof obj === 'undefined' || obj == null) {
			return;
		}

		this.recipeId = obj.recipeId;
		this.displayName = obj.displayName;
		this.description = obj.description;
		this.imageUrl = obj.imageUrl;
		this.workTime = obj.workTime;
		this.workTimeRange = obj.workTimeRange;
		this.cookingTime = obj.cookingTime;
		this.cookingTimeRange = obj.cookingTimeRange;
		this.difficulty = obj.difficulty;
		this.contributor = obj.contributor;
	}

	toJSON() {
		return {
			recipeId: this.recipeId
		};
	}

}

export class XpArticle {
	@observable displayName = null;
	@observable id = null;
    @observable path = null;

    constructor(obj) {
        if (typeof obj !== 'undefined' && obj != null) {
            this.displayName = obj.displayName;
            this.id = obj.id;
            this.path = obj.path;
        }
    }
}

class Contributor {
	@observable name = '';

	constructor(obj) {
		if(typeof obj === 'undefined') {
			return;
		}

		this.name = obj.name;
		this.rootpath = obj.rootpath;
		this.urlprefix = obj.urlprefix;
		this.isDefault = obj.isDefault;
		this.order = obj.order;
	}
}

class IngredientLine {
	id = null;
	@observable heading = null;
	@observable preText = "";
	@observable amount = "";
	@observable unit = null;
	@observable ingredient = null;
	@observable postText = "";
	@observable omissible = false;
	@observable alternatives = [];

	constructor(obj) {
		this.id = shortid.generate();
		if(typeof obj === 'undefined') {
			return;
		}

		this.heading = obj.heading || null;
		this.preText = obj.preText;
		this.amount = obj.amount;
		this.changeIngredient(obj.ingredient);
		this.changeUnit(obj.unit);
		this.postText = obj.postText;
		this.omissible = obj.omissible;
		this.alternatives = obj.alternatives ?
			obj.alternatives.map(r => new AlternativeIngredient(r)) :
			[]
	}

	@computed
	get unitName() {
		if(!this.unit) {
			return "";
		}

		if(parseFloat(this.amount) >= 2.) {
			return this.unit.plural;
		} else {
			return this.unit.singular;
		}
	}

	@computed
	get ingredientName() {
		if(!this.ingredient) {
			return "";
		}

		if(!isEmpty(this.ingredient.plural) && parseFloat(this.amount) >= 2.) {
			return this.ingredient.plural;
		} else {
			return this.ingredient.singular;
		}
	}

	@action
	changeUnit(data) {
		if(data !== null) {
			this.unit = observable(data);
		} else {
			this.unit = null;
		}
	}

	@action
	changeIngredient(data) {
		if(data !== null) {
			this.ingredient = observable(data);
		} else {
			this.ingredient = null;
		}
	}

	@action
	addAlternativeIngredient(alternativeIngredient) {
		this.alternatives.push(alternativeIngredient);
	}

	@action
	removeAlternativeIngredient(alternativeIngredient) {
		removeElement(this.alternatives, a => (a.ingredient.id === alternativeIngredient.ingredient.id));
	}

	toJSON() {
		const {id, heading, ...rest} = toJS(this);
		return {
			...rest,
			ingredient: rest.ingredient ? {id: rest.ingredient.id} : null,
			unit: rest.unit ? {id: rest.unit.id} : null
		};
	}
}

const recipeStore = new RecipeStore();
export default recipeStore;
