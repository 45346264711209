import { observable, action, computed, toJS } from 'mobx';
import axios from 'axios';
import {RECIPE_API, RIVERSAND_API, RIVERSAND_KEY} from "../utils/consts";
import snackbarStore from "./SnackbarStore";
import {isEmpty} from "lodash-es";
import {Recipe} from "./RecipeStore";

class IngredientStore {
	@observable currentIngredient = new Ingredient();
	@observable loading = false;

	@action
	getIngredientById(id) {
		this.loading = true;
		return axios.get(`${RECIPE_API}/ingredients/${id}`)
			.then((response) => this.parseCurrentIngredient(response))
			.finally(() => {this.loading = false});
	}

	@action
	updateWithNameFromRiversand(articleNo) {
		this.loading = true;
		return axios.get(`${RECIPE_API}/ingredients/riversandName/` + articleNo)
			.then((response) => this.parseRiversandResponse(response))
			.catch(this.handleRiversandError)
			.finally(() => {this.loading = false});

	}

	@action.bound
	parseRiversandResponse(response) {
		this.currentIngredient.singular = response.data;
	}

	@action
	handleRiversandError(error) {
		if(error.response.status === 404) {
			snackbarStore.addSnack("", "Finner ikke ingrediens i Riversand");
		} else {
			console.error(error);
			snackbarStore.addSnack("ERROR: " + error.response.status, "Noe gikk galt ved henting av data fra Riversand... sjekk konsollen for mer informasjon");
		}
	}


	@action.bound
	parseCurrentIngredient(response) {
		this.instantiateCurrentIngredient(response.data);
		return Promise.resolve();
	}

	setRecipe(recipe) {
        this.currentIngredient.recipe = recipe;
	}

	getRecipe() {
        return this.currentIngredient.recipe;
	}

	updateCurrentIngredient(id) {
		this.loading = true;
		return axios.put(`${RECIPE_API}/ingredients/${id}`, this.currentIngredient.generateJson())
			.finally(() => {this.loading = false});
	}

	createCurrentIngredient() {
		this.loading = true;
		return axios.post(`${RECIPE_API}/ingredients`, this.currentIngredient.generateJson())
			.finally(() => {this.loading = false});
	}

	@action.bound
	instantiateCurrentIngredient(data) {
		this.currentIngredient = new Ingredient(data);
	}

	@action.bound
	clear() {
		this.currentIngredient = new Ingredient();
		return Promise.resolve();
	}
}

export class Ingredient {
	@observable id = "";
	@observable plural = "";
	@observable singular = "";
	@observable genericName = "";
	@observable contentKey = "";
	@observable articleNo = "";
	@observable inUse = true;
	@observable usageCount = 0;
	@observable status = "";
	@observable recipe;

	constructor(resp) {
		if(typeof resp === "undefined") {
			return;
		}

		this.id = resp.id || "";
		this.plural = resp.plural;
		this.singular = resp.singular;
		this.genericName = resp.genericName;
		this.contentKey = resp.contentKey;
		this.articleNo = resp.articleNo;
		this.inUse = resp.inUse;
		this.usageCount = resp.usageCount || 0;
		this.status = resp.status;
		this.recipe = resp.recipe;
	}

	@computed
	get displayName() {

		if(!isEmpty(this.plural)) {
			return this.plural;
		} else {
			return this.singular;
		}
	}

	toJSON() {
		const { ...rest } = this;
		return rest;
	}

	generateJson() {
		return JSON.stringify(this, null, 4);
	}
}

export class AlternativeIngredient {
	@observable ingredient = null;
	@observable amount = "";
	@observable unit = null;
	@observable replacementTypes = [];

	constructor(obj) {
		this.changeIngredient(obj.ingredient);
		this.amount = obj.amount;
		this.changeUnit(obj.unit);
		this.replacementTypes = obj.replacementTypes;
	}

	@action
	changeIngredient(data) {
		if (data !== null && data !== undefined) {
			this.ingredient = observable(data);
		} else {
			this.ingredient = null;
		}
	}

	@action
	changeUnit(data) {
		if (data !== null && data !== undefined) {
			this.unit = observable(data);
		} else {
			this.unit = null;
		}
	}

	@computed
	get unitName() {
		if (!this.unit) {
			return '';
		} else if (parseFloat(this.amount) >= 2.) {
			return this.unit.plural;
		} else {
			return this.unit.singular;
		}
	}
}

export class AlternativeReplacementType {
	@observable name = "";

	constructor(obj) {
		this.name = obj.name;
	}
}

const ingredientStore = new IngredientStore();

export default ingredientStore;