import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import SearchInput from "../../common/SearchInput";
import {get} from "lodash-es";
import {enonicSearchApiConfig} from "../../utils/enonicConfig";
import SelectedRelatedXpArticles from "./SelectedRelatedXpArticles";
import {XpArticle} from "../../stores/RecipeStore";

@inject('recipeStore')
@observer
class RelatedXpArticles extends React.Component {

	constructor(props) {
		super(props);
	}

	@autobind
	handleResultSelected(item, _) {
		const { recipeStore } = this.props;
        recipeStore.addRelatedXpArticle(new XpArticle(item._source));
	}

	static renderSuggestion(entry) {
		return (
			<span>
				<small className="float-right">{get(entry, "contributor", "")}</small>
				{get(entry, "_source.displayName", "Ukjent artikkel")}
			</span>
		);
	}

	@autobind
	isNotAlreadyInList(entry) {
		const alreadySelectedArticles = this.props.recipeStore.currentRecipe.relatedXpArticles;
        return alreadySelectedArticles
            .filter(e => e.id === entry._source.id)
            .length === 0;
    }

	render() {
		 return (
			<Fragment>
				<SearchInput field="_source.displayName"
                             id="relatedEnonicSearch"
                             onSelectItem={this.handleResultSelected}
							 clearInputOnSelect={true}
							 apiConfig={enonicSearchApiConfig}
							 filterCallback={this.isNotAlreadyInList}
							 renderSuggestion={RelatedXpArticles.renderSuggestion}
							 placeholder="Søk og velg XP innehold"
                />
				<SelectedRelatedXpArticles/>
			</Fragment>
		);
	}
}

RelatedXpArticles.propTypes = {

};

export default RelatedXpArticles;
