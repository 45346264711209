import {action, observable, computed} from "mobx";
import recipeStore from './RecipeStore';

class UIStore {

	// tracks mandatory components without value
	@observable mandatoryFieldComponents = new Map();

	@action.bound
	addMandatoryFieldComponent(source, id) {
		let path = this.findPath(source, recipeStore.currentRecipe) + "." + id;
		this.mandatoryFieldComponents.set(path, true);
	}

	@action.bound
	removeMandatoryFieldComponent(source, id) {
		let path = this.findPath(source, recipeStore.currentRecipe) + "." + id;
		this.mandatoryFieldComponents.delete(path);
	}

	@computed
	get allFieldsValid() {
		return this.mandatoryFieldComponents.size === 0;
	}

	findPath(a, obj) {
		if(a === obj) {
			return "currentRecipe";
		}

		for(let key in obj) {
			if(obj.hasOwnProperty(key)) {
				if(a === obj[key]) {
					return key;

				} else if(obj[key] && typeof obj[key] === "object") {
					let path = this.findPath(a, obj[key]);
					if(path) {
						return "currentRecipe." + key + "." + path;
					}
				}
			}
		}
	}
}



const uiStore = new UIStore();

export default uiStore;

