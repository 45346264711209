import React from 'react';
import {Form, FormGroup, Input, Label} from 'reactstrap';
import {OrderManagementUI, SearchInput, SyncedInput} from '../../common';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import autobind from "autobind-decorator";
import {RECIPE_API} from "../../utils/consts";
import {set} from "lodash-es";
import {FaBars, FaMinus} from "react-icons/fa";
import IngredientSearchInput from "../../common/IngredientSearchInput";

@inject("recipeStore")
@inject("ingredientStore")
@inject("uiStore")
@observer
class AlternativeIngredientPrLine extends React.Component {

    @autobind
    updateUnit(selection) {
        this.props.line.changeUnit(selection);
    }

    @autobind
    updateIngrendient(selection) {
        this.props.line.changeIngredient(selection);
    }

    render() {
        const { line, handleRemove, filterIngredientSearchResult} = this.props;

        const unitApiConfig = {
            endpoint: `${RECIPE_API}` + "/units/search",
            queryParams: {
                searchKey: "term"
            }
        };

        return (
            <div>
                <Form className="ingredientline mb-2" inline onSubmit={(e) => e.preventDefault()}>
                    <OrderManagementUI handleRemove={handleRemove}/>
                    <FormGroup className="w-10">
                        <Label for="amount" hidden>Tekst foran</Label>
                        <SyncedInput type="lax-number" className="w-100" source={line} id="amount"  mandatory={line.unit && line.unitName !== ''} />
                    </FormGroup>
                    {' '}
                    <FormGroup className="w-10">
                        <Label for="unit" hidden>Enhet</Label>
                        <SearchInput field="singular" className="w-100" value={line.unitName} source={line}
                                     id="unit" mandatory={line.amount && line.amount !== 0}
                                     onSelectItem={this.updateUnit} apiConfig={unitApiConfig}/>
                    </FormGroup>
                    {' '}
                    <FormGroup className="margin-left-45 w-20">
                        <Label for="ingredientAltSearch" hidden>Alternative ingredients</Label>
                        <IngredientSearchInput
                            id="ingredientAltSearch"
                            ingredient={line.ingredient}
                            value={line.ingredient.singular}
                            onSelectItem={this.updateIngrendient}
                            clearInputOnSelect={false}
                            mandatory={true}
                            filterCallback={filterIngredientSearchResult}
                        />
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

AlternativeIngredientPrLine.propTypes = {
    line: PropTypes.object.isRequired,
    handleRemove: PropTypes.func,
    filterIngredientSearchResult: PropTypes.func
};

export default AlternativeIngredientPrLine;