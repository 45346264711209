import React from 'react';
import { observer } from 'mobx-react';

import PropTypes from "prop-types";
import autobind from "autobind-decorator";
import {Button} from "reactstrap";
import Spinner from "../../common/Spinner";

@observer
class Thumbnail extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mediaIsLoading: true
		};
	}

	@autobind
	mediaLoaded(e) {
		this.setState({mediaIsLoading: false});
	}


	render() {
		const { thumbnailUrl, onDeleteCallback, onClickCallback, children } = this.props;
		const { mediaIsLoading } = this.state;

		return (
			<div className="thumbnail-container clickable">
				<img
					className={"img-thumbnail"}
					onLoad={this.mediaLoaded}
					onClick={onClickCallback}
					src={thumbnailUrl}
				/>
				<Button tabIndex="-1" className="bg-transparent text-black-50 close media-delete-button" onClick={onDeleteCallback} aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</Button>
				{children}
				{mediaIsLoading &&
				<div className="spinnerContainer">
					<Spinner enabled={mediaIsLoading}/>
				</div>
				}
			</div>
		);
	}
}

Thumbnail.propTypes = {
	thumbnailUrl: PropTypes.string,
	onDeleteCallback: PropTypes.func,
	onClickCallback: PropTypes.func
};

export default Thumbnail;