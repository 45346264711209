import {
	RECIPE_API,
	XP_API,
	XP_API_KEY,
	GOOGLE_API_KEY,
	GOOGLE_YOUTUBE_API,
	MENY_API,
	CLOUDINARY_CONFIG,
	CONFIRM_ON_NAVIGATE
} from './consts-APP_ENV';

const STORAGE_KEY = "token";

export {
	RECIPE_API,
	XP_API,
	XP_API_KEY,
	GOOGLE_API_KEY,
	GOOGLE_YOUTUBE_API,
	STORAGE_KEY,
	MENY_API,
	CLOUDINARY_CONFIG,
	CONFIRM_ON_NAVIGATE
};