import React from 'react';
import {Editor, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import PropTypes from "prop-types";
import { debounce } from 'lodash-es';
import 'draft-js/dist/Draft.css';
import {InitEditorState, InsertExternalContentLink, convertToHTML, getSelectedTextAndEntity, moveSelectionToEnd } from "./DraftUtils";
import LinkModal from "./LinkModal";
import ReactDOM from "react-dom";


const { hasCommandModifier } = KeyBindingUtil;

function bindKeys(e) {
	if (e.keyCode === "K".charCodeAt(0) && hasCommandModifier(e)) { //  Ctrl + K
		return 'insert-link';
	}
	return getDefaultKeyBinding(e);
}

@inject("recipeStore")
@observer
class RichTextEditor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			editorState: InitEditorState(props.value),
			showModal: false
		};
		this.persistToStoreDebounced = debounce(this.persistToStore, 300); // debounce 0.3 sec
	}

	componentWillReceiveProps(newProps) {
		let oldValue = convertToHTML(this.state.editorState.getCurrentContent());
		oldValue = oldValue === "<br>" ? "" : oldValue;
		if(newProps.value !== oldValue) {
			this.setState({
				editorState: InitEditorState(newProps.value)
			});
		}
	}

	componentWillMount() {
		this.setCaretToEndOfLine();
	}

	@autobind
	setCaretToEndOfLine() {
		this.setState({
			editorState: moveSelectionToEnd(this.state.editorState)
		});
	}

	@autobind
	handleKeyCommand(command) {
		if (command === 'insert-link') {
			this.setState({
				showModal: true,
				selectedTextAndEntity: getSelectedTextAndEntity(this.state.editorState)
			});
			return 'handled';
		}
		return 'not-handled';
	}

	@autobind
	onChange(editorState) {
		this.setState({editorState});
		this.persistToStoreDebounced();
	}

	@autobind
	onClick(e){
		if(e.ctrlKey) { // check if link was ctrl + clicked and open dialog
			e.preventDefault();
			this.setState({
				showModal: true,
				selectedTextAndEntity: getSelectedTextAndEntity(this.state.editorState)
			});
		} else {
			this.editor.focus();
		}
	}


	@autobind
	persistToStore() {
		let contentState = this.state.editorState.getCurrentContent();
		let html = convertToHTML(contentState);

		if(html === "<br>") { // bug in stateToHTML - converts empty string to <br>
			html = null;
		}

		const fakeEvent = {
			target: {
				value: html
			}
		};
		this.props.onChange(fakeEvent);
	}

	@autobind
	renderSuggestion(result) {
		this.setState({editorState: InsertExternalContentLink(this.state.editorState, result)});
		this.onModalToggle();
	}


	@autobind
	onModalToggle() {
		this.setState({showModal: !this.state.showModal});
	}

	@autobind
	handleOnFocus(e) {
		if(this.props.rowsExpand) {
			let node = ReactDOM.findDOMNode(this);
			node.style.height = "calc(" + this.props.rowsExpand + "*1.5rem + 0.75rem)";
		}
	}

	@autobind
	handleOnBlur(e) {
		if(this.props.rowsExpand) {
			let node = ReactDOM.findDOMNode(this);
			node.style.height = "calc(" + this.props.rows + "*1.5rem + 0.75rem)";
		}
	}

	render() {
		const { rows, className } = this.props;

		return (
			<div
			    className={'rich-editor form-control ' + className}
				style={{'--richtext-rows': rows}}
				onClick={this.onClick}
			>
				<Editor
					ref={node => this.editor = node}
					editorState={ this.state.editorState }
					onChange={this.onChange}
					placeholder={this.props.placeholder}
					handleKeyCommand={this.handleKeyCommand}
					keyBindingFn={bindKeys}
					onFocus={this.handleOnFocus}
					onBlur={this.handleOnBlur}
					stripPastedStyles={this.props.stripPastedStyles}
				/>
				<LinkModal
					isOpen={this.state.showModal}
					onModalToggle={this.onModalToggle}
				    renderSuggestion={(result) => this.renderSuggestion(result)}
					selectedTextAndEntity={this.state.selectedTextAndEntity}
				/>
			</div>
		);
	}
}



RichTextEditor.propTypes = {
	recipeStore: PropTypes.object,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
    stripPastedStyles: PropTypes.bool,
	rows: PropTypes.number,
	rowsExpand: PropTypes.number,
	className: PropTypes.string
};

export default RichTextEditor;