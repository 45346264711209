import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Redirect } from 'react-router-dom';


import '../../assets/login.css';
import snackbarStore from "../stores/SnackbarStore";

@inject("authStore")
@observer
class Authentication extends React.Component {
	@observable username = "";
	@observable password = "";

	@autobind
	handleLogin() {
		this.props.authStore.login(this.username, this.password)
			.catch(this.handleFailure);
	}

	@autobind
	handleFailure(result) {
		if (result.response && result.response.status) {
			if (result.response.status === 401) {
				snackbarStore.addSnack("Innlogging feilet", "Feil brukernavn eller passord", true);
			} else if (result.response.status === 403) {
				snackbarStore.addSnack("Innlogging feilet", "Bruker har ikke tilgang til denne tjenesten", true);
			} else if (result.response.status === 408) {
				snackbarStore.addSnack("Innlogging feilet", "Forespørselen tok unormalt lang tid", true);
			} else if (result.response.status === 500) {
				snackbarStore.addSnack("Innlogging feilet", "En intern serverfeil har oppstått", true);
			} else if (result.response.status === 503) {
				snackbarStore.addSnack("Innlogging feilet", "Tjenesten er ikke tilgjengelig for øyeblikket", true);
			} else {
				snackbarStore.addSnack("Innlogging feilet", result.response.data, true);
			}
		} else if (result.response) {
			snackbarStore.addSnack("Innlogging feilet", result.response.data, true);
		} else {
			snackbarStore.addSnack("Innlogging feilet", "Får ikke kontakt med server", true)
		}
	}

	@autobind
	handleEnter(e) {
		if(e.keyCode === 13) {
			this.handleLogin();
		}
	}

	render() {
		const { from } = this.props.location.state || { from: { pathname: '/' } };
		if(this.props.authStore.loggedIn) {
			return <Redirect to={from} />;
		}

		return (
			<Form className="form-login">
				<FormGroup>
					<Label for="username">Brukernavn</Label>
					<Input type="text" name="username" value={this.username} onChange={e => (this.username = e.target.value)} onKeyUp={this.handleEnter} />
				</FormGroup>
				<FormGroup>
					<Label for="password">Passord</Label>
					<Input type="password" name="password" value={this.password} onChange={e => (this.password = e.target.value)} onKeyUp={this.handleEnter} />
				</FormGroup>
				<Button onClick={this.handleLogin}>Logg inn</Button>
			</Form>
		);
	}
}

Authentication.propTypes = {
	authStore: PropTypes.object
};

export default Authentication;