import { inject, observer } from 'mobx-react';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import Spinner from "../../common/Spinner";
import { Diff } from 'react-diff-view';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Table,
	Input
} from 'reactstrap';
import FocusLock from "react-focus-lock";

@inject("recipeDiffStore")
@inject("recipeStore")
@observer
class VersionsModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			compareList: [],
			showDiff: false
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({compareList: []});
	}

	@autobind
	setVersion(recipeVersionId) {
		const { toggle, setVersionCallback } = this.props;
		toggle();
		setVersionCallback(recipeVersionId);
	}

	@autobind
	toggleDiff() {
		this.setState({showDiff: !this.state.showDiff});
	}

	@autobind
	toggleCheckbox(e, recipeVersionId) {
		let arr = this.state.compareList;

		if(e.target.checked) {
			arr.push(recipeVersionId);
		} else {
			arr = arr.filter(i => i !== recipeVersionId);
		}

		this.setState({compareList: arr});
	}

	@autobind
	fetchDiff() {
		this.props.recipeDiffStore.fetchVersions(this.state.compareList[0], this.state.compareList[1])
			.then(() => this.toggleDiff());
	}

	render() {
		const { toggle, isOpen, recipeStore, recipeDiffStore } = this.props;
		const { loading, allVersions } = recipeStore;
		const { loading: loadingDiff, diffs} = recipeDiffStore;
		const {compareList, showDiff} = this.state;

		return (
			<Fragment>
				<Modal className="versions-modal" toggle={toggle} isOpen={isOpen} autoFocus={false} fade={false}>
					<ModalHeader>Velg versjon</ModalHeader>
					<ModalBody>
						<Table>
							<thead>
								<tr><th>UUID</th><th> </th><th>Dato</th><th> </th><th>Sammmenlign</th></tr>
							</thead>
							<tbody>
							{
								allVersions.map((recipeVersion, i) =>
									<tr key={recipeVersion.id}>
										<td>{recipeVersion.id}</td>
										<td>{recipeVersion.label && <span className="version-label">{recipeVersion.label}</span>}</td>
										<td>{recipeVersion.created}</td>
										<td>
											<Button color="primary" onClick={() => this.setVersion(recipeVersion.id)}>Bruk</Button>
										</td>
										<td className="text-center align-middle">
											<Input addon type="checkbox" disabled={compareList.length >= 2 && !compareList.includes(recipeVersion.id)} onChange={(e) => this.toggleCheckbox(e, recipeVersion.id)}/>
										</td>

									</tr>
								)
							}
							</tbody>
						</Table>
						<Spinner enabled={loading || loadingDiff} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" disabled={compareList.length !== 2} onClick={this.fetchDiff}>Sammenlign</Button>
						<Button color="primary" onClick={() => this.props.toggle()}>Lukk</Button>
					</ModalFooter>
				</Modal>

				<Modal className="versions-modal" isOpen={showDiff} toggle={this.toggleDiff} autoFocus={false} fade={false}>
					<ModalHeader toggle={this.toggleDiff}>Sammenligning </ModalHeader>
					<FocusLock disabled={!showDiff}>
						<div className="float-right mr-2">Farvekoder:
							<span style={{background: "salmon"}} className="m-2">fjernet</span>
							<span style={{background: "lightgreen"}} className="mr-2">lagt til</span>
						</div>
						<ModalBody>
							{ diffs && diffs.map((diff, index) =>
									<div key={index}>
										<span className="font-weight-bold">{diff.path}</span>
										<Diff
											inputA={diff.rhs}
											inputB={diff.lhs}
											type="words"
										/>
									</div>
								)
							}
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggleDiff}>Lukk</Button>
						</ModalFooter>
					</FocusLock>
				</Modal>

			</Fragment>
		);
	}
}

VersionsModal.propTypes = {
	toggle: PropTypes.func,
	isOpen: PropTypes.bool,
	setVersionCallback: PropTypes.func
};

export default VersionsModal;