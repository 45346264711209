import React,{ Fragment }  from 'react';
import {InputGroupAddon, InputGroup, Input, Button, Col, InputGroupText} from 'reactstrap';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import ResultCard from './ResultCard';

@inject("searchStore")
@observer
class Search extends React.Component {

	constructor(props) {
		super(props);
		this.state = {searchAllFields: false};
	}

	@autobind
	handleSearch() {
		this.props.searchStore.search(100, this.state.searchAllFields);
	}

	@autobind
	handleKeyPress(e) {
		if(e.charCode === 13) {
			this.handleSearch();
		}
	}

	@autobind
	toggleCheckbox(e) {
		this.setState({searchAllFields: !this.state.searchAllFields});
	}

	render() {
		const searchStore = this.props.searchStore;
		const results = searchStore.searchResults;
		const {searchAllFields} = this.state;

		return (
			<Fragment>
				<Col className={"mb-3"}>
					<div>
						<InputGroup>
							<Input autoFocus onKeyPress={this.handleKeyPress} onChange={e => searchStore.searchFieldContent = e.target.value} />
							<InputGroupAddon addonType="append">
								<Button onClick={this.handleSearch}>Søk</Button>
							</InputGroupAddon>
						</InputGroup>
						<div>
							<Input addon type="checkbox" checked={searchAllFields} onChange={this.toggleCheckbox}/>
							<span className="small ml-1 align-text-bottom">Søk blant alle feltene.</span>
						</div>
					</div>
				</Col>
				<Col>
					{searchStore.searching && <p>Søker...</p>}

					{results && results.map(r => <ResultCard key={r.recipeId} {...r} />)}

					{results && results.length === 0 && !searchStore.searching && searchStore.searchTermForResults.length > 0 &&
					<p>Fant ingen treff på {searchStore.searchTermForResults}...</p>
					}
				</Col>
			</Fragment>
		);
	}
}

Search.propTypes = {
	searchStore: PropTypes.object
};

export default Search;