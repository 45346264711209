import React from "react";
import OverviewTab from "./OverviewTab";
import classnames from 'classnames';
import DetailsTab from "./DetailsTab";
import PublishTab from "./PublishTab";
import { Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import PropTypes from "prop-types";
import TabButtons from "./TabButtons";
import Spinner from "../../common/Spinner";
import recipeStore from "../../stores/RecipeStore";
import siteStore from "../../stores/SiteStore";
import {CONFIRM_ON_NAVIGATE} from "../../utils/consts";


@withRouter
@inject("recipeStore")
@inject("siteStore")
@inject("snackbarStore")
@inject("enonicStore")
@observer
class Editor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: "overview"
		};
	}

	componentDidMount() {
		const { recipeStore, siteStore, match } = this.props;
		if(match && !recipeStore.recipeIsDirty || !CONFIRM_ON_NAVIGATE) {
			if(match.params.tab) {
				this.setState({
					activeTab: match.params.tab
				});
			} else {
				this.replaceSlug("overview");
			}

			siteStore.initContributors();

			if(match.params.recipeId !== "new") {
				recipeStore.getRecipeById(match.params.recipeId);
			} else {
				recipeStore.createEmptyRecipe();
			}
		}

	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}

		this.replaceSlug(tab);
	}

	replaceSlug(slug) {
		const { location, history } = this.props;
		history.replace({
			pathname: location.pathname.replace(/(\/recipe\/[^\/]*).*$/, '$1/' + slug)
		});
	}

	render() {
		const enonicStoreLoading = this.props.enonicStore.loading;
		const recipeStoreLoading = this.props.recipeStore.loading;
		const siteStoreLoading = this.props.siteStore.loading;

		return (
			<div>
				<Nav tabs className="recipe-tabs">
					<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === 'overview' })}
							onClick={() => { this.toggle('overview'); }}
						>
							Om oppskriften
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === 'details' })}
							onClick={() => { this.toggle('details'); }}
						>
							Ingredienser og fremgangsmåte
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === 'publish' })}
							onClick={() => { this.toggle('publish'); }}
						>
							Klargjøring
						</NavLink>
					</NavItem>
					<TabButtons />
				</Nav>

				<TabContent activeTab={this.state.activeTab}>
					<TabPane tabId="overview">
						<OverviewTab/>
					</TabPane>
					<TabPane tabId="details">
						<DetailsTab/>
					</TabPane>
					<TabPane tabId="publish">
						<PublishTab/>
					</TabPane>
				</TabContent>
				<Spinner enabled={enonicStoreLoading || recipeStoreLoading || siteStoreLoading} />
			</div>
		);
	}

}

Editor.propTypes = {
	recipeStore: PropTypes.object,
	snackbarStore: PropTypes.object,
	enonicStore: PropTypes.object,
	siteStore: PropTypes.object,
};


export default Editor;