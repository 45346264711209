import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from "react-sortable-hoc";
import { Button } from 'reactstrap';
import { MdDelete, MdDragHandle } from 'react-icons/md';

const SortHandle = SortableHandle(() => (
	<Button tabIndex="-1" color="link" className="close moveHandle deleteOver" aria-label="Move">
		<MdDragHandle />
	</Button>
));

const OrderManagementUI = ({ handleRemove }) => (
	<React.Fragment>
		<Button tabIndex="-1" color="link" className="close deleteHandle" onClick={handleRemove} aria-label="Close">
			<MdDelete />
		</Button>
		<SortHandle />
	</React.Fragment>
);

OrderManagementUI.propTypes = {
	handleRemove: PropTypes.func.isRequired
};

export default OrderManagementUI;
