import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import SelectedRelatedRecipes from "./SelectedRelatedRecipes";
import SearchInput from "../../common/SearchInput";
import {get} from "lodash-es";
import {recipeSearchApiConfig} from "../../utils/enonicConfig";

@inject("recipeStore")
@observer
class RelatedRecipes extends React.Component {

	constructor(props) {
		super(props);
	}

	@autobind
	handleResultSelected(item, value) {
		const { recipeStore } = this.props;
		recipeStore.addRelatedRecipe(item);
	}

	renderSuggestion(entry) {
		return (
			<span>
				<small className="float-right">{get(entry, "contributor", "")}</small>
				{get(entry, "displayName", "Ukjent oppskrift")}
			</span>
		);
	}

	@autobind
	isNotAlreadyInList(entry) {
		const alreadySelectedRecipes = this.props.recipeStore.currentRecipe.relatedRecipes;
		return alreadySelectedRecipes
			.filter(e => e.recipeId === entry.recipeId)
			.length === 0;
	}

	render() {
		 return (
			<Fragment>
				<SearchInput field="displayName"
				             id="relatedRecipeSearch"
				             onSelectItem={this.handleResultSelected}
				             clearInputOnSelect={true}
				             apiConfig={recipeSearchApiConfig}
				             filterCallback={this.isNotAlreadyInList}
				             renderSuggestion={this.renderSuggestion}
				             placeholder="Søk og velg oppskrift"
				/>
				<SelectedRelatedRecipes/>
			</Fragment>
		);
	}

}

RelatedRecipes.propTypes = {

};


export default RelatedRecipes;